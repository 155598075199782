import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AllScannedAddedCupsTable from "./AllScannedAddedCupsTable";

const DisplayAllCups = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>All Cups | NFC | Admin</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" gutterBottom>
            All availabe cups
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <AllScannedAddedCupsTable reload={0} />
        </Stack>
      </Container>
    </>
  );
};

export default DisplayAllCups;
