import React from "react";

import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Box, Button,Grid } from "@mui/material";

function refactorCupModels(arrayOfArray) {
  const tempArray = [];
  arrayOfArray?.forEach((cuptModel) => {
    cuptModel[1]?.forEach((modelSizeArray) => {
      tempArray.push({
        rowIndex: tempArray.length + 1,
        cupType: cuptModel[0],
        ...modelSizeArray,
      });
    });
  });
  return tempArray;
}

const TableToDisplayModels = ({ cupModelsData }) => {

   // customize filter tool
   function CustomizedToolbarLayout() {
    return (
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <Box width="100%" sx={{ p: 2 }}>
            <GridToolbarQuickFilter
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "rgba(158, 158, 158, 1)",
                  borderRadius: "10px",
                  borderColor: "rgba(158, 158, 158, 1)",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          <CustomToolbar />
        </Grid>
      </Grid>
    );
  }

  // custome toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "rowIndex", headerName: "ID", width: 100 },
    {
      field: "cupType",
      headerName: "Cup Type",
      width: 160,
      editable: true,
    },
    {
      field: "cupSize",
      headerName: "Cup Size",
      width: 160,
      editable: true,
    },
    {
      field: "modelID",
      headerName: "Model Id",
      type: "string",
      width: 200,
      editable: true,
    },
    // {
    //   field: "action",
    //   headerName: "Restrict Model",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Button variant="contained" color="error" size="small">
    //           Disable Model
    //         </Button>
    //       </>
    //     );
    //   },
    // },
  ];

  const tempCupModelData = refactorCupModels(cupModelsData);

  return (
    <>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={
            tempCupModelData && tempCupModelData.length > 0
              ? tempCupModelData
              : []
          }
          columns={columns}
          slots={{ toolbar: CustomizedToolbarLayout }}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
              {
                outline: "none",
              },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          // disableRowSelectionOnClick
        />
      </Box>
    </>
  );
};

export default TableToDisplayModels;
