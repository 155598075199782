import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
  Stack,
  Typography,
  Grid,
  Button,
  TextField,
  Box,
  MenuItem,
} from "@mui/material";

import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

import { baseUrl } from "../../../utils/apiUrl";

import AllScannedAddedCupsTable from "./AllScannedAddedCupsTable";

import QrCodeScanner from "./QrCodeScanner";

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
// function to return the cupsizes array to dipaly availabe cupsizes

function cupSizesArray(cupType, availableCupsArray) {
  let cupSizes = [];
  availableCupsArray.forEach((availableCup) => {
    if (availableCup[0] === cupType) {
      cupSizes = availableCup[1];
    }
  });
  return [...cupSizes];
}

const ScanAndAddCup = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [availableCups, setAvailableCups] = useState([]);

  const [nfcData, setNfcData] = useState(null);

  const [reloadStatus, setReloadStatus] = useState(0);

  const [cupDetail, setCupDetail] = useState({
    cupID: "",
    modelID: "",
    cupType: "",
  });

  const [isScanned, setIsScanned] = useState(false);

  const [isNFCAvailable, setIsNFCAvailable] = useState(false);

  const [cupChiptUIN, setcupChiptUIN] = useState("");

  const handleChange = (e) => setcupChiptUIN(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      // navigate(`/recieve/cup/info/${cupChiptUIN?.chipt_uin}`);

      setIsScanned(true);
      setCupDetail({
        cupID: cupChiptUIN,
        modelID: "",
        cupType: "",
      });
    } catch (error) {
      console.log({ error });
    }
  };

  // update cupdetail value
  const handleChangecupDetail = (e) => {
    console.log(e.target.value);
    if (e.target.name === "cupType") {
      setCupDetail({
        ...cupDetail,
        cupType: e.target.value,
        modelID: "",
      });
    } else {
      setCupDetail({ ...cupDetail, [e.target.name]: e.target.value });
    }
  };

  // scan the nfc
  const scan = async () => {
    if ("NDEFReader" in window) {
      setIsNFCAvailable(true);
      setIsScanned(false);
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();

        dispatch(openSnackbar("Scan started successfully.", "success"));
        ndef.onreadingerror = () => {
          dispatch(
            openSnackbar(
              "Cannot read data from the NFC tag. Try another one?",
              "warning"
            )
          );
        };

        ndef.onreading = (event) => {
          console.log("NDEF message read.");
          onReading(event); // Find function below
        };
      } catch (error) {
        dispatch(
          openSnackbar(`Error! Scan failed to start: ${error}.`, "warning")
        );
      }
    } else {
      dispatch(openSnackbar("device does not supported NFC ", "warning"));
      setIsNFCAvailable(false);
    }
  };

  // to read cup detail
  const onReading = ({ message, serialNumber }) => {
    setCupDetail({ ...cupDetail, cupID: `${serialNumber}` });
    setNfcData(serialNumber);
    dispatch(
      openSnackbar(`Scanned Cup Serial No : ${serialNumber}`, "success")
    );

    console.log("working==>", message);
    // CHIPTLIYKEAZJB6
    if (serialNumber) {
      // navigate(`/recieve/cup/info/CHIPTLIYKEAZJB6`);
      setIsScanned(true);
    }
  };

  const onWrite = async () => {
    try {
      const ndef = new window.NDEFReader();
      await ndef.write({
        records: [{ recordType: "text", data: "Hellow World!" }],
      });
      console.log(`Value Saved!`);
    } catch (error) {
      console.log(error);
    }
  };

  // handleSubbmitCupDetails

  const handleSubmitCupDetails = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(`${baseUrl}/cup/add/new/cup/details`, {
        modelID: cupDetail.modelID,

        cupID: cupDetail.cupID,
      });
      setReloadStatus(reloadStatus + 1);
      setCupDetail({
        cupID: "",
        modelID: "",
        cupType: "",
      });
      setIsScanned(false);
      if (
        (res.status === 200 || res.status === 201) &&
        res.data &&
        res.data.message
      ) {
        dispatch(openSnackbar(res.data.message, "success"));
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
      }
    } catch (error) {
      setCupDetail({
        cupID: "",
        modelID: "",
        cupType: "",
      });
      setIsScanned(false);
      console.log("error=>", error);
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  // get cup models and cupsizes
  const getGeneratedCupList = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${baseUrl}/cup/available/cup/csv`);
      console.log("cups==>", res);
      if (
        res.status === 200 &&
        res.data.cupAvailable &&
        res.data.cupAvailable?.length > 0
      ) {
        console.log("===>", res.data.cupAvailable);
        setAvailableCups(res.data.cupAvailable);
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar(res?.data?.message, "warning"));
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getGeneratedCupList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Scan and Add Cup | CHIPT NFC</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" textTransform={"capitalize"} gutterBottom>
            Scan and add Cup
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        {isScanned ? (
          <form onSubmit={handleSubmitCupDetails}>
            <Grid container rowGap={2} columnGap={1}>
              <Grid item xs={12} sm={5.75} md={3.75}>
                <TextField
                  fullWidth
                  name="cupID"
                  label="Cup Serial Number"
                  focused
                  required
                  disabled
                  size="small"
                  placeholder="scan to get serial number"
                  value={cupDetail.cupID}
                />
              </Grid>
              <Grid item xs={12} sm={5.75} md={3.75}>
                <TextField
                  label="select cup type"
                  name="cupType"
                  select
                  fullWidth
                  required
                  value={cupDetail?.cupType ? cupDetail?.cupType : ""}
                  size="small"
                  onChange={handleChangecupDetail}
                  placeholder="will be assigned automatically"
                  sx={{ textTransform: "capitalize" }}
                >
                  <MenuItem disabled value="0">
                    -- please select a cuptype --
                  </MenuItem>

                  {availableCups?.map((type, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={type[0]}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {type[0]}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={5.75} md={3.75}>
                <TextField
                  label="select cup size"
                  name="modelID"
                  select
                  fullWidth
                  required
                  value={cupDetail?.modelID ? cupDetail?.modelID : ""}
                  size="small"
                  onChange={handleChangecupDetail}
                  placeholder="Select a Size of Cup"
                  sx={{ textTransform: "capitalize" }}
                >
                  <MenuItem disabled value="0">
                    -- select cupsize --
                  </MenuItem>

                  {cupSizesArray(cupDetail.cupType, availableCups)?.map(
                    (size, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={size.modelID}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {size.cupSize}
                        </MenuItem>
                      );
                    }
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"start"}
                  direction={"row"}
                >
                  <Button
                    variant="contained"
                    size="small"
                    type="submit"
                    color="primary"
                  >
                    Add Cup
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    type="reset"
                    color="warning"
                    onClick={() => {
                      setIsScanned(false);
                      setCupDetail({});
                      setcupChiptUIN("");
                    }}
                  >
                    RESCAN
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sm={5} alignItems={"center"}>
                {/* <Typography variant="h4" sx={{ mb: 5 }}>
                  Tap NFC
                </Typography> */}
                <h3>Tap NFC</h3>
                <Box
                  width={"100%"}
                  maxWidth={"350px"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => scan()}
                >
                  <img
                    width={"100%"}
                    src="./assets/icons/nfc.gif"
                    alt="SCANNER"
                  />
                </Box>
                {nfcData && isNFCAvailable ? (
                  <Typography mt={2}>{nfcData}</Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                mt={1}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography
                  variant="h5"
                  textAlign={"center"}
                  fontStyle={"bold"}
                >
                  {" "}
                  OR
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                {/* <Typography variant="h4" color={"primary"} sx={{ mb: 5 }}>
                Enter NFC Serial Number
              </Typography>
              <form onSubmit={handleSubmit}>
                <Stack spacing={1} alignItems={"start"}>
                  <TextField
                    size="small"
                    name="chipt_uin"
                    label="Chipt NFC serial"
                    placeholder="Enter NFC Serilal Number"
                    onChange={handleChange}
                    required
                  />
                  <Button type="submit" variant="outlined" color="primary">
                    Proceed
                  </Button>
                </Stack>
              </form> */}
                <QrCodeScanner
                  isScanned={isScanned}
                  setIsScanned={setIsScanned}
                  cupDetail={cupDetail}
                  setCupDetail={setCupDetail}
                  scrollToTop={scrollToTop}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack mt={2} direction={{ sm: "row", xs: "column" }}>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/bulkupload")}
                    color="primary"
                  >
                    Bulk Upload | Upload CSV
                  </Button>
                </Stack>
                <Typography variant="h4" color={"primary"} sx={{ mt: 5 }}>
                  Enter NFC Serial Number
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Stack mt={1} spacing={1} alignItems={"start"}>
                    <TextField
                      size="small"
                      name="chipt_uin"
                      label="Chipt NFC serial"
                      placeholder="Enter NFC Serilal Number"
                      onChange={handleChange}
                      required
                    />
                    <Button type="submit" variant="outlined" color="primary">
                      Proceed
                    </Button>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </>
        )}
        <Stack mt={2}>
          <Typography variant="h5">All Added Cups</Typography>
          <AllScannedAddedCupsTable reload={reloadStatus} />
        </Stack>
      </Container>
    </>
  );
};

export default ScanAndAddCup;
