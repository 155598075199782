import { Button, Typography, Box, Grid, Stack } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";

// components
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fDateTime } from "../../../utils/formatTime";
import Label from "../../../components/label/Label";

import { isLoading } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";

// mock

function refactorOrderList(requestRows) {
  const tempArray = [];
  requestRows.forEach((requestData) => {
    tempArray.push({
      rowIndex: tempArray.length + 1,
      _id: requestData._id,
      requestStatus: requestData.requestStatus,
      approvalStatus: requestData.approvalStatus,
      vendorName: requestData?.vendor?.name,
      contactNumber: `+(${requestData.vendor.countryCode})${requestData.vendor.contactNumber}`,
      email: requestData.vendor.email,
      noOfItems: requestData?.cupsRequested?.length,
      dateOfRequest: requestData.dateOfRequest,
      address: `${
        requestData.shippingAddress?.plotNumber
          ? requestData.shippingAddress?.plotNumber
          : ""
      } ${
        requestData.shippingAddress?.address
          ? requestData.shippingAddress?.address
          : ""
      } ${
        requestData.shippingAddress?.city
          ? requestData.shippingAddress?.city
          : ""
      } ${
        requestData.shippingAddress?.state
          ? requestData.shippingAddress?.state
          : ""
      } ${
        requestData.shippingAddress?.country
          ? requestData.shippingAddress?.country
          : ""
      }  ${
        requestData.shippingAddress?.zipCode
          ? requestData.shippingAddress?.zipCode
          : ""
      }`,
    });
  });
  return tempArray;
}

// -----------------------------------------
// customize filter tool
function CustomizedToolbarLayout() {
  return (
    <Grid container alignItems={"center"}>
      <Grid item xs={12} order={{ xs: 1, sm: 0 }} sm={6}>
        <Box width="100%" sx={{ p: 2 }}>
          <GridToolbarQuickFilter
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "rgba(158, 158, 158, 1)",
                borderRadius: "10px",
                borderColor: "rgba(158, 158, 158, 1)",
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} display={"flex"} justifyContent={"end"}>
        <CustomToolbar />
      </Grid>
    </Grid>
  );
}
// custome toolbar
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton /> */}
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}
// -----------------------------------------

const OrdersHolderTable = ({ type, title, getApiUrl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stockRequests, setStockRequests] = useState([]);

  const allRowDataIncludingIndex =
    stockRequests && stockRequests.length > 0
      ? refactorOrderList(stockRequests)
      : [];

  console.log("===>", allRowDataIncludingIndex);

  // column definiton

  const columns = [
    { field: "rowIndex", filterable: false, headerName: "ID", width: 90 },

    {
      field: "requestStatus",
      headerName: "Request",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <Label
              color={
                (params.row.approvalStatus === "STATUS_REJECTED" && "error") ||
                "success"
              }
            >
              {params.row.requestStatus}
            </Label>
          </>
        );
      },
    },
    {
      field: "vendorName",
      headerName: "Requested By",
      type: "string",
      width: 180,
      editable: false,
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      type: "string",
      width: 180,
      editable: false,
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   type: "string",
    //   width: 180,
    //   editable: false,
    // },
    {
      field: "noOfItems",
      headerName: "NoI",
      description: "No Of Items",
      type: "Number",
      width: 100,
      editable: false,
    },
    {
      field: "dateOfRequest",
      headerName: "DOR",
      type: "Number",
      description: "Date of Request",
      width: 180,
      align: "left",
      editable: false,
      renderCell: (params) => {
        return <>{fDateTime(params.row.dateOfRequest)}</>;
      },
    },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 250,
    // },
    {
      field: "action",
      headerName: "Action",
      description:
        "This led you to take action over order request like accept, reject and so.",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {(params.row?.approvalStatus === "STATUS_PENDING" &&
              params.row?.requestStatus === "PROCESSING") ||
            params.row?.requestStatus === "SHIPPED" ? (
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => navigate(`/orders/${params.row._id}`)}
              >
                {params.row?.requestStatus === "PROCESSING" ||
                params.row.approvalStatus === "processing"
                  ? "Proceed"
                  : params.row?.requestStatus === "DISPATCH" ||
                    params.row.approvalStatus === "dispatch"
                  ? "Dispatch"
                  : params.row?.requestStatus === "SHIPPED" ||
                    params.row.approvalStatus === "shipped"
                  ? "Deliver"
                  : params.row?.requestStatus === "DELIVERED" ||
                    params.row.approvalStatus === "delivered"
                  ? "Closed"
                  : "Take Action"}
              </Button>
            ) : (
              <Label
                color={
                  params.row?.requestStatus === "PROCESSING" ||
                  params.row.approvalStatus === "processing"
                    ? "warning"
                    : params.row?.requestStatus === "DISPATCH" ||
                      params.row.approvalStatus === "dispatch"
                    ? "info"
                    : params.row?.requestStatus === "SHIPPED" ||
                      params.row.approvalStatus === "shipped"
                    ? "primary"
                    : params.row?.requestStatus === "DELIVERED" ||
                      params.row.approvalStatus === "delivered"
                    ? "warning"
                    : "error"
                }
              >
                {/* {params.row.requestStatus} */}

                {params.row?.requestStatus === "PROCESSING" ||
                params.row?.approvalStatus === "processing"
                  ? "Proceed"
                  : params.row?.requestStatus === "DISPATCH" ||
                    params.row?.approvalStatus === "dispatch"
                  ? "Dispatch"
                  : params.row?.requestStatus === "SHIPPED" ||
                    params.row?.approvalStatus === "shipped"
                  ? "Shipped"
                  : params.row?.requestStatus === "DELIVERED" ||
                    params.row?.approvalStatus === "delivered"
                  ? "Closed"
                  : "Closed"}
              </Label>
            )}
          </>
        );
      },
    },
    {
      field: "view",
      filterable: false,
      headerName: "Preview",
      description: "You can view and can update the order request",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/orders/${params.row._id}`)}
            >
              View
            </Button>
          </>
        );
      },
    },
  ];

  // fetch data
  const getAllRequests = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}${getApiUrl}`);
      console.log("requsts list res==>", res);
      setStockRequests(res?.data?.stockRequest);
      // setFilteredArrayStocks(res?.data?.stockRequest);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };
  useEffect(() => {
    getAllRequests();
  }, []);

  return (
    <>
      <Stack>
        <Typography variant="h4">{title}</Typography>
      </Stack>
      {allRowDataIncludingIndex && allRowDataIncludingIndex.length > 0 ? (
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={
              allRowDataIncludingIndex.length > 0
                ? allRowDataIncludingIndex
                : []
            }
            columns={columns}
            getRowId={(row) => row._id}
            slots={{ toolbar: CustomizedToolbarLayout }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            sx={{
              textTransform: "capitalize",
              minHeight: "250px",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      ) : (
        <>
          <Label color={"error"}>No data found</Label>
        </>
      )}
    </>
  );
};

export default OrdersHolderTable;
