export const ActionTypes = {
  ADMIN_LOGIN: "ADMIN_LOGIN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  ADMIN_AUTH: "ADMIN_AUTH",
  ADMIN_PROFILE_DATA_UPDATE: "ADMIN_PROFILE_DATA_UPDATE",

  OPEN_SNACKBAR: "OPEN_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",

  SET_ISLOADING: "SET_OPEN_ISLOADING",
};
