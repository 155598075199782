import { Avatar, Container, Grid, Stack, Typography } from "@mui/material";

import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VendorStockInfoCard from "./VendorStockInfoCard";
import { baseUrl } from "../../../utils/apiUrl";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

const AllVendorStocks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allVendorStocksDetails, setAllVendorStocksDetails] = useState([]);
  //   vendor/all/stores/stocks
  const getAllVendorStocks = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${baseUrl}/vendor/all/stores/stocks`);
      console.log("res==>", res?.data?.vendorsStocks);
      if (
        res.status === 200 &&
        res?.data?.vendorsStocks &&
        res?.data?.vendorsStocks.length > 0
      ) {
        setAllVendorStocksDetails([...res?.data?.vendorsStocks]);
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getAllVendorStocks();
  }, []);

  return (
    <>
      <Helmet>
        <title> Admin | Vendor Profile </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" gutterBottom>
            All Vendor Stocks
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack mt={1}>
          {allVendorStocksDetails.length > 0 ? (
            <Grid container columnGap={2} rowGap={2}>
              {allVendorStocksDetails?.map((stock, index) => {
                return (
                  <VendorStockInfoCard key={index} data={stock} index={index} />
                );
              })}
            </Grid>
          ) : (
            "No Data Found"
          )}
        </Stack>
      </Container>
    </>
  );
};

export default AllVendorStocks;
