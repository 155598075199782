import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";

const AddNewPackage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    price: "",
    freeCupCredits: "",
    totalCredits: "",
    isActive: true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(isLoading(true));
      const formattedFormValues = {
        ...formValues,
        price: Number(formValues.price),
        freeCupCredits: Number(formValues.freeCupCredits),
        totalCredits: Number(formValues.totalCredits),
      };
      const res = await axios.post(`${baseUrl}/package`, formattedFormValues);
      dispatch(isLoading(false));
      dispatch(openSnackbar("Package added successfully", "success"));
      navigate(-1);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Something went wrong";
      dispatch(openSnackbar(errorMessage, "error"));
      dispatch(isLoading(false));
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Package | NFC | CHIPT </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" textTransform={"capitalize"} gutterBottom>
            Add Package
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&:hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            spacing={2}
            mb={2}
          >
            <Typography variant="h4" sx={{ color: "grey" }}>
              Package Info
            </Typography>
          </Stack>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              rowGap={2.5}
              columnGap={3}
              mb={2}
            >
              <Grid item xs={12} md={5.5}>
                <TextField
                  label="Package Name"
                  name="name"
                  fullWidth
                  required
                  value={formValues.name}
                  size="small"
                  onChange={handleChange}
                  placeholder="Enter Package Name"
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  label="Description"
                  name="description"
                  fullWidth
                  required
                  value={formValues.description}
                  size="small"
                  onChange={handleChange}
                  placeholder="Enter Description"
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  label="Price (in RM)"
                  name="price"
                  type="number"
                  fullWidth
                  required
                  value={formValues.price}
                  size="small"
                  onChange={handleChange}
                  placeholder="Enter Price"
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  label="Free Cup Credits"
                  name="freeCupCredits"
                  type="number"
                  fullWidth
                  required
                  value={formValues.freeCupCredits}
                  size="small"
                  onChange={handleChange}
                  placeholder="Enter Free Cup Credits"
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  label="Total Credits"
                  name="totalCredits"
                  type="number"
                  fullWidth
                  required
                  value={formValues.totalCredits}
                  size="small"
                  onChange={handleChange}
                  placeholder="Enter Total Credits"
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.isActive}
                      onChange={handleChange}
                      name="isActive"
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </Grid>
            </Grid>
            <Stack mt={2} direction={"row"}>
              <Button type="submit" variant="contained" color="primary">
                Add Package
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Container>
    </>
  );
};

export default AddNewPackage;
