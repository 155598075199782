import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

// @mui
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  Container,
  Typography,
  Button,
  Tooltip,
  Alert,
  Paper,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// components
import PolylineIcon from "@mui/icons-material/Polyline";
import Iconify from "../components/iconify";
// sections
import { AppWidgetSummary } from "../sections/@dashboard/app";
import { isLoading, openSnackbar } from "../redux/action/defaultActions";
import { baseUrl } from "../utils/apiUrl";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [adminCardData, setAdminCardData] = useState({});
  const [openGenerateListModal, setOpenGenerateListModal] = useState();
  const [alert, setAlert] = useState(true);

  const handleClose = async () =>
    setOpenGenerateListModal(!openGenerateListModal);
  const handleAlertclose = () => setAlert(false);

  const getDashboardCardDetails = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/cup/dashboard/all/details`);

      setAdminCardData(res.data.adminData);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getDashboardCardDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | NFC | CHIPT </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        {/* fxemoji:beermug */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Cups"
              total={adminCardData?.cups ? adminCardData?.cups : 0}
              color="info"
              icon={"game-icons:coffee-mug"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Requests"
              total={
                adminCardData?.cupRequests ? adminCardData?.cupRequests : 0
              }
              color="warning"
              icon={"ep:list"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Vendors"
              total={adminCardData?.vendors ? adminCardData?.vendors : 0}
              icon={"healthicons:market-stall"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Users"
              total={adminCardData?.customers ? adminCardData?.customers : 0}
              color="success"
              icon={"ci:users"}
            />
          </Grid>
          <Grid item xs={12}>
            {alert ? (
              <Alert onClose={handleAlertclose} severity="warning">
                This button will redirect you to model generation page where you
                can add different cup models !
              </Alert>
            ) : (
              ""
            )}

            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Tooltip
                title="This button will redirect you to model generation page where you
              can add different cup models !"
                arrow
              >
                {/* <Button
                sx={{ mt: 2 }}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => navigate("/generatelist")}
              >
                Generate List
              </Button> */}

                <Button
                  sx={{ mt: 2 }}
                  // size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/generatecupmodels")}
                  startIcon={<PolylineIcon />}
                >
                  Generate Cup Models
                </Button>
              </Tooltip>
              <Button
                variant="contained"
                onClick={() => navigate("/bulkupload")}
                color="primary"
              >
                Bulk Upload | Upload CSV
              </Button>
            </Stack>

            {/* <Label>
              <Iconify icon="fontisto:info" />
            </Label> */}
          </Grid>
          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Cup Orders / Coffee Transactions"
              subheader="(+43%) than last year"
              chartLabels={[
                "01/01/2003",
                "02/01/2003",
                "03/01/2003",
                "04/01/2003",
                "05/01/2003",
                "06/01/2003",
                "07/01/2003",
                "08/01/2003",
                "09/01/2003",
                "10/01/2003",
                "11/01/2003",
              ]}
              chartData={[
                {
                  name: "Coffee Cups",
                  type: "column",
                  fill: "solid",
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: "Booked",
                  type: "area",
                  fill: "gradient",
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: "Returned",
                  type: "line",
                  fill: "solid",
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Coffee Orders Scenario"
              chartData={[
                { label: "America", value: 4344 },
                { label: "Asia", value: 5435 },
                { label: "Europe", value: 1443 },
                { label: "Africa", value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={12} lg={12}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: index,
                title: "News and Update",
                description: "Content",
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Quick Links
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button
                variant="outlined"
                startIcon={<Iconify icon="healthicons:market-stall" />}
                onClick={() => navigate("/vendor/create")}
              >
                Add Vendor
              </Button>
              <Button
                variant="outlined"
                startIcon={<Iconify icon="bxs:coffee-togo" />}
                onClick={() => navigate("/cups/add")}
              >
                Add Cup Inventory
              </Button>
              {/* <Button
                // sx={{ mt: 2 }}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => navigate("/generatecupmodels")}
              >
                Generate Cup Models
              </Button> */}
            </Stack>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  "1983 Cups, orders, $4220",
                  "12 Invoices have been paid",
                  "Order #37745 from September",
                  "New order placed #XF-2356",
                  "New order placed #XF-2346",
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
