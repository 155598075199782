import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isLoading, openSnackbar } from "../redux/action/defaultActions";
import Iconify from "../components/iconify";
import { baseUrl } from "../utils/apiUrl";
import ChangePasswordModal from "../components/password/ChangePasswordModal";
import { adminProfileDataUpdate, logout } from "../redux/action/adminActions";

const ProfilePage = () => {
  const navigate = useNavigate();
  const { nfcTngAdmin } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [edit, setedit] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [editMode, seteditMode] = useState(false);
  const [fileUrl, setfileUrl] = useState(null);
  const [profilePicture, setprofilePicture] = useState(null);
  const [file, setfile] = useState(null);
  const [adminValues, setadminValues] = useState({});
  const [phone, setPhone] = useState("");
  const handleChangePhone = (newPhone) => {
    console.log("ooo=>", newPhone);
    setPhone(newPhone);
  };
  const handleChangeadminValues = (e) =>
    setadminValues({ ...adminValues, [e.target.name]: e.target.value });

  const toggleChangePasswordModal = () =>
    setOpenChangePassword(!openChangePassword);
  // =========handlefilechange =======
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setfile(e.target.files[0]);
      setfileUrl(URL.createObjectURL(file));
      //
    }
  };
  // =================================

  const getAdminProfileData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/account/profile/details`);
      // account/profile/details
      console.log("res==>", res?.data);
      dispatch(
        adminProfileDataUpdate({
          ...res?.data?.admin,
        })
      );
      setadminValues({
        email: res?.data?.admin?.email,
        name: res?.data?.admin?.name,
        contactNumber: res?.data?.admin?.contactNumber,
        countryCode: res?.data?.admin?.countryCode,
      });
      setPhone(
        `+${res?.data?.admin?.countryCode}${res?.data?.admin?.contactNumber}`
      );
      setprofilePicture(res?.data?.admin?.profilePicture?.url);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log({ error });
    }
  };

  // ====function to update profile information
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.put(`${baseUrl}/account/profile/update`, {
        // username: "chipt_admin1",
        name: adminValues?.name,
        primaryEmail: adminValues?.email,
        countryCode: phone?.replace(/\s/g, "")?.slice(1, 3),
        primaryNumber: phone?.replace(/\s/g, "")?.slice(-10),
      });
      dispatch(openSnackbar("profile updated successfully", "success"));
      await getAdminProfileData();
      setedit(false);
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log({ error });
      dispatch(isLoading(false));
    }
  };

  // ==========update profile picture ==========
  const handleUpdateProfilePicture = async () => {
    dispatch(isLoading(true));
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.put(
        `${baseUrl}/account/profile/image/upload`,
        formData
      );

      await getAdminProfileData();
      dispatch(isLoading(false));
      dispatch(openSnackbar("profile picture updates successfully", "success"));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  // =======handleupdatepassword function ==========
  const handleUpdatePassword = async (values) => {
    console.log("===>", values);
    dispatch(isLoading(true));
    try {
      console.log("working");
      const res = await axios.put(`${baseUrl}/account/password/change`, {
        ...values,
      });
      dispatch(logout());
      dispatch(isLoading(false));
      navigate("/login");
      dispatch(openSnackbar("Password Updated Successfully", "success"));
    } catch (error) {
      console.log({ error });
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
        dispatch(isLoading(false));
      }
    }
  };
  // ===============================================
  useEffect(() => {
    getAdminProfileData();
    // setprofilePicture("/assets/images/avatars/avatar_default.jpg");
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Profile Picture </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <Typography variant="h4">Hi, {nfcTngVendor?.email}</Typography> */}
        <Card>
          <CardContent>
            <Stack
              direction={{ sm: "row", xs: "column" }}
              spacing={4}
              alignItems={"start"}
              justifyContent={"start"}
            >
              <Stack p={1}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <Box
                      width={"30px"}
                      height={"30px"}
                      bgcolor={"lightgrey"}
                      sx={{
                        transition: "ease-in-out 300ms",
                        color: "primary.dark",
                        "&:hover": {
                          bgcolor: "info.dark",
                          color: "white",
                        },
                      }}
                      borderRadius={"100%"}
                      color={"info.dark"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      textAlign={"center"}
                      fontSize={"8px"}
                      htmlFor="icon-button-photo"
                      component={"label"}
                    >
                      <Iconify icon="mingcute:edit-3-fill" />
                    </Box>
                  }
                >
                  <input
                    accept="image/*"
                    id="icon-button-photo"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    type="file"
                  />
                  <Avatar
                    src={fileUrl && fileUrl ? fileUrl : profilePicture}
                    alt="Nazil Ali"
                    sx={{ width: "100px", minHeight: "100px" }}
                  />
                </Badge>
                {fileUrl && file ? (
                  <Button
                    size="small"
                    onClick={handleUpdateProfilePicture}
                    variant="outlined"
                    sx={{ mt: 2 }}
                  >
                    Upload
                  </Button>
                ) : (
                  ""
                )}
              </Stack>
              <Stack justifyContent={"start"}>
                <Typography variant="h3" textTransform={"capitalize"} fontFamily={"monospace"}>
                  {nfcTngAdmin?.name}
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <span>
                    <Iconify icon="carbon:email-new" />
                  </span>
                  <Typography
                    variant="body2"
                    component={"div"}
                    fontFamily={"monospace"}
                  >
                    {adminValues?.email}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <span>
                    <Iconify icon="solar:phone-outline" />
                  </span>
                  <Typography
                    variant="body2"
                    component={"div"}
                    fontFamily={"monospace"}
                  >
                    {`(+${nfcTngAdmin?.countryCode}) ${
                      nfcTngAdmin?.contactNumber
                        ? nfcTngAdmin?.contactNumber
                        : "N/A"
                    }`}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack alignItems={"end"}>
              <Button variant="text" onClick={toggleChangePasswordModal}>
                Change Password
              </Button>
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{ mt: 2, position: "relative" }}
          component={"form"}
          onSubmit={handleSubmit}
        >
          <CardHeader
            title={"Admin Profile Data"}
            subheader={"admin information"}
          />
          <Box sx={{ position: "absolute", right: 20, top: 20 }}>
            <IconButton
              color={edit ? "warning" : "info"}
              onClick={() => setedit(!edit)}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              {edit ? (
                <Iconify icon="fluent:arrow-exit-20-filled" />
              ) : (
                <Iconify icon="akar-icons:edit" />
              )}
            </IconButton>
            <Box sx={{ display: { xs: "none", sm: "inline-block" } }}>
              <Button
                variant="outlined"
                color={edit ? "warning" : "info"}
                size="small"
                onClick={() => setedit(!edit)}
                endIcon={
                  edit ? (
                    <Iconify icon="iconamoon:close" />
                  ) : (
                    <Iconify icon="uil:info" />
                  )
                }
              >
                {edit ? "Close" : "Edit Info"}
              </Button>
            </Box>
          </Box>
          <CardContent>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              rowGap={2.5}
              columnGap={3}
              mb={2}
            >
              <Grid item xs={12} md={5.5}>
                <TextField
                  name="name"
                  disabled={!edit}
                  fullWidth
                  label="Name"
                  size="small"
                  value={adminValues?.name ? adminValues?.name : ""}
                  onChange={handleChangeadminValues}
                  placeholder="Enter your name"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="iconamoon:profile-light" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  size="small"
                  disabled
                  value={adminValues?.email ? adminValues?.email : ""}
                  onChange={handleChangeadminValues}
                  placeholder="Enter Email of Vendor"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="clarity:email-line" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <MuiTelInput
                  fullWidth
                  disabled={!edit}
                  label="Mobile Number"
                  defaultCountry="MY"
                  size="small"
                  value={phone}
                  placeholder="Enter Phone Number"
                  onChange={handleChangePhone}
                />
              </Grid>

              {/* <Grid item xs={12} md={5.5}>
                <TextField
                  fullWidth
                  name="address"
                  label="Address"
                  disabled={!edit}
                  size="small"
                  defaultValue={"12345678"}
                  value={adminValues?.address ? adminValues?.address : ""}
                  onChange={handleChangeadminValues}
                  placeholder="Enter Address"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="gis:position-o" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  fullWidth
                  name="city"
                  label="City"
                  disabled={!edit}
                  size="small"
                  value={adminValues?.city ? adminValues?.city : ""}
                  onChange={handleChangeadminValues}
                  placeholder="Enter City Name"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="healthicons:city-outline" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  fullWidth
                  required
                  disabled={!edit}
                  name="state"
                  label="State"
                  size="small"
                  value={adminValues?.state ? adminValues?.state : ""}
                  onChange={handleChangeadminValues}
                  placeholder="Enter State"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="streamline:shopping-building-real-home-tower-building-house-estate" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  fullWidth
                  disabled={!edit}
                  name="country"
                  label="Country"
                  size="small"
                  value={adminValues?.country ? adminValues?.country : ""}
                  onChange={handleChangeadminValues}
                  placeholder="Country Name"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="solar:flag-linear" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <TextField
                  fullWidth
                  disabled={!edit}
                  name="zipCode"
                  label="Pincode / Zipcode"
                  size="small"
                  type="number"
                  value={adminValues?.zipCode ? adminValues?.zipCode : ""}
                  onChange={
                    adminValues?.zipCode?.length >= 6
                      ? () => {}
                      : (e) => handleChangeadminValues(e)
                  }
                  placeholder="Enter Zipcode"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="ri:file-zip-line" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
            </Grid>
            {edit ? (
              <Stack mt={2.5} direction={"row"} alignItems={"center"}>
                <Button variant="contained" type="submit">
                  Update
                </Button>
              </Stack>
            ) : (
              ""
            )}
          </CardContent>
        </Card>
      </Container>
      <ChangePasswordModal
        open={openChangePassword}
        handleClose={toggleChangePasswordModal}
        handleAction={handleUpdatePassword}
      />
    </>
  );
};

export default ProfilePage;
