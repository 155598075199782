import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import CountUp from "react-countup";
import Papa from "papaparse"
import Iconify from "../iconify";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { baseUrl } from "../../utils/apiUrl";

const GenerateListMainPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [csvIsReady, setCsvIsReady] = useState(false);
  const [generatedList, setGeneratedList] = useState([]);
  const [listRows, setListRows] = useState([{ cupType: "", cupSize: "" }]);

  // add new rows
  const handleAddNewRow = () => {
    const temp = [...listRows];
    temp.push({ cupType: "", cupSize: "" });
    setListRows([...temp]);
  };

  // remove rows
  const handleRemoveRow = (pos) => {
    dispatch(isLoading(true));
    const temp = [...listRows];
    temp.splice(pos, 1);
    setListRows([...temp]);
    dispatch(isLoading(false));
  };

  // set values in rows
  const handleChangeRowValues = (e, index) => {
    const temp = [...listRows];
    temp[index] = { ...temp[index], [e.target.name]: e.target.value };
    setListRows([...temp]);
    setCsvIsReady(false);
  };

  // generate list
  const hadnleGenerateList = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const list = [];
      let itemListLength = 0;

      listRows?.forEach((row, index) => {
        itemListLength += row.numberOfCups * 1;
        // let iterate = row.numberOfCups * 1;
        // for (iterate; iterate > 0; iterate -= 1) {
        //   list.push({
        //     cupType: row.cupType,
        //     cupSize: row.cupSize,
        //     cupId: `${index}${iterate}`,
        //   });
        // }
      });
      console.log("lenth==>", itemListLength);
      if (itemListLength < 100000) {
        const res = await axios.post(`${baseUrl}/cup/csv/generation`, {
          cupData: listRows,
        });
        console.log("===>", res);
        setGeneratedList([...res?.data?.cupJson]);
        setCsvIsReady(true);
        dispatch(isLoading(false));
      } else {
        dispatch(
          openSnackbar(
            `List exceeds number of allowed items. Upto 1L items can be generated. You have request ${itemListLength} item list`,
            "warning"
          )
        );
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      }
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };
  const convertToCSV = () => {
    const jsonData = JSON.stringify([...generatedList]);

    // const { jsonData } = listRows;
    const csvData = Papa.unparse(jsonData, [
      {
        quotes: false, // or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: null, // or array of strings
      },
    ]);
    downloadCSV(csvData);
  };

  const downloadCSV = (csvData) => {
    console.log(csvData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "generatedlist.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // reset
  const handleResetRequestNew = () => {
    setListRows([{ cupType: "", cupSize: "" }]);
    setCsvIsReady(false);
  };
  // console.log("generated list", generatedList);
  return (
    <>
      <Helmet>
        <title> Admin | Generate List </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" gutterBottom>
            Generate List
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack component={"form"} onSubmit={hadnleGenerateList}>
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            rowGap={2}
            columnGap={1}
          >
            {listRows?.map((listItem, index) => {
              return (
                <Grid
                  key={index}
                  item
                  container
                  display={"flex"}
                  justifyContent={"space-between"}
                  // component={Paper}

                  rowGap={2}
                  columnGap={1}
                  xs={12}
                  p={1}
                  py={2}
                  bgcolor={"white"}
                  // borderRadius={"10px"}
                  boxShadow={"2px 13px 11px -15px #afa7a7"}
                >
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="body2"
                      color={"info.dark"}
                      component={"b"}
                      fontWeight={"bold"}
                      gutterBottom
                    >
                      Row : {index + 1}
                    </Typography>
                    <IconButton
                      sx={{ color: "error.dark" }}
                      disabled={listRows?.length === 1}
                      onClick={() => handleRemoveRow(index)}
                    >
                      <Iconify icon="gala:remove" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={3.75}>
                    <TextField
                      label="select cup modal"
                      name="cupType"
                      select
                      fullWidth
                      disabled={csvIsReady}
                      required
                      value={listItem?.cupType}
                      size="small"
                      onChange={(e) => handleChangeRowValues(e, index)}
                      placeholder="will be assigned automatically"
                      // focused
                    >
                      <MenuItem value="classic">royal</MenuItem>
                      <MenuItem value="premium">premium</MenuItem>
                      <MenuItem value="normal">normal</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3.75}>
                    <TextField
                      label="select size"
                      name="cupSize"
                      select
                      required
                      disabled={csvIsReady}
                      value={listItem?.cupSize}
                      onChange={(e) => handleChangeRowValues(e, index)}
                      fullWidth
                      size="small"
                      placeholder="will be assigned automatically"
                      // focused
                    >
                      <MenuItem value="small">small</MenuItem>
                      <MenuItem value="medium">medium</MenuItem>
                      <MenuItem value="large">large</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3.75}>
                    <TextField
                      label="no of cups"
                      fullWidth
                      disabled={csvIsReady}
                      size="small"
                      name="numberOfCups"
                      type="number"
                      value={listItem?.numberOfCups}
                      onChange={(e) => handleChangeRowValues(e, index)}
                      placeholder="number of cups to be manufacture"
                      focused
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Stack alignItems={"end"} mt={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<Iconify icon="mingcute:add-fill" />}
              onClick={handleAddNewRow}
            >
              Add
            </Button>
          </Stack>
          <Stack alignItems={"center"} direction={"row"} spacing={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={csvIsReady}
              type="submit"
            >
              Generate List
            </Button>
            {csvIsReady && generatedList?.length > 0 ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={convertToCSV}
                  endIcon={<Iconify icon="basil:cloud-download-solid" />}
                >
                  Download List
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="reset"
                  onClick={handleResetRequestNew}
                >
                  Generate New
                </Button>
              </>
            ) : (
              ""
            )}
          </Stack>
        </Stack>
        {csvIsReady && generatedList?.length > 0 ? (
          <Stack mt={3}>
            <Card sx={{ bgcolor: "warning.light", textAlign: "center" }}>
              <CardContent>
                <Typography>Requested List Items</Typography>
                <Typography variant="h3">
                  {<CountUp end={generatedList?.length} duration={10} />}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

export default GenerateListMainPage;
