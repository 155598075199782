import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Stack, Container, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { isLoading, openSnackbar } from "../redux/action/defaultActions";

import { baseUrl } from "../utils/apiUrl";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdited, setisEdited] = useState(false);
  const [addData, setAddData] = useState("");

  // ----------------------------------
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setisEdited(true);
    setAddData(data);
  };
  // ----------------------------------
  const getPrivacyPolicyData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/privacypolicy`);
      if (res.status === 200 && res?.data?.privacyPolicy) {
        setAddData(res?.data?.privacyPolicy);
      }
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      }
      dispatch(openSnackbar("something went wrong", "error"));
      dispatch(isLoading(false));
    }
  };
  // ----------------------------------
  // ----------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`${URL}/privacypolicy`, {
        privacyData: addData,
      });
      dispatch(openSnackbar("saved successfully", "success"));
      setisEdited(false);
      getPrivacyPolicyData();
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      }
      dispatch(openSnackbar("something went wrong", "error"));
      dispatch(isLoading(false));
    }
  };
  // ----------------------------------
  useEffect(() => {
    setisEdited(false);
    getPrivacyPolicyData();
  }, []);

  // ----------------------------------
  // ----------------------------------
  return (
    <>
      <Helmet>
        <title> Add Cup | NFC | CHIPT </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" textTransform={"capitalize"} gutterBottom>
            Provicy and Policy
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <form onSubmit={handleSubmit}>
            <CKEditor
              editor={ClassicEditor}
              data={addData}
              config={{
                height: "300px",
                placeholder: "add privacy and policy ruels.....",
              }}
              onReady={(e, editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              // onChange={(e, editor) => {
              //   setAddData(editor.getData());
              // }}
              onChange={handleEditorChange}
              onBlur={(e, editor) => {}}
              onFocus={(e, editor) => {}}
              toolbar={[
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "alignment:left", // Left align
                "alignment:center", // Center align
                "alignment:right", // Right align
                "|",
                "fontColor",

                // Include the fontColor in the toolbar
              ]}
            />
            <Stack mt={1} direction={"row"} alignItems={"center"}>
              <Button
                variant="contained"
                disabled={!isEdited}
                sx={{
                  bgcolor: "green !important",
                  color: "white",

                  mt: 1,
                  "&.Mui-disabled ": {
                    color: "rgb(255 252 252 / 26%)",
                    bgcolor: "rgba(82 79 79 / 12%) !important",
                    boxShadow: "none",
                  },
                }}
                size="small"
                type="submit"
              >
                Save
              </Button>
            </Stack>
          </form>
        </Stack>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
