import { combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { adminReducer } from "./adminReducer";
import { setIsLoading, snacbarReducer } from "./defaultReducer";

const allAdminReducer = combineReducers({
  nfcTngAdmin: adminReducer,
  snackbar: snacbarReducer,
  loading: setIsLoading,
});

const reducer = allAdminReducer;
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["nfcTngAdmin"],
};

const adminPersistReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  adminPersistReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // process.env.NODE_ENV !== 'production'
);
export const persistor = persistStore(store);
// export default allAdminReducer;
