import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import axios from "axios";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";

import { login } from "../../../redux/action/adminActions";
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { nfcTngAdmin } = useSelector((state) => state);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const [loginDetails, setLoginDetails] = useState({});

  const handleChangeLoginValues = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  console.log("data==>", nfcTngAdmin);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      console.log("new changes");
      const res = await axios.post(`${baseUrl}/account/signin`, {
        ...loginDetails,
      });
      console.log({ res });
      dispatch(login({ email: loginDetails?.email }));
      dispatch(isLoading(false));
      navigate("/", { replace: true });
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            value={loginDetails?.email ? loginDetails?.email : ""}
            onChange={handleChangeLoginValues}
          />

          <TextField
            name="password"
            label="Password"
            value={loginDetails?.password ? loginDetails?.password : ""}
            onChange={handleChangeLoginValues}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Checkbox name="remember" label="Remember me" />
          <Link variant="subtitle2" underline="hover">
            Forgot password?
          </Link>
        </Stack> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={firstTimeLogin}
                onClick={() => setFirstTimeLogin(!firstTimeLogin)}
              />
            }
            label="First Time Login"
          /> */}
          {/* <Checkbox name="remember" label="Remember me" /> */}

          <Link
            variant="subtitle2"
            underline="hover"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/forget-password")}
          >
            Forgot password?
          </Link>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          // onClick={handleClick}
        >
          Login
        </LoadingButton>
      </form>
    </>
  );
}
