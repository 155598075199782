import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

const SingleOrderRequestDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [openConfirmationModal, setopenConfirmationModal] = useState(false);
  // const [confirmationMsg, setconfirmationMsg] = useState("");
  const [currentActionData, setcurrentActionData] = useState({
    confirmationMsg: "",
    apiUrl: "",
  });

  const [orderRequestDetails, setOrderRequestDetails] = useState({});

  // handleOpenConfirmation modal

  const handleOpenConfirmationMmodal = (actionType, url) => {
    setcurrentActionData({
      confirmationMsg: actionType,
      apiUrl: url,
    });
    toggleConfirmationModal();
  };

  // update state openConfirmationModal
  const toggleConfirmationModal = () =>
    setopenConfirmationModal(!openConfirmationModal);

  // handle close confirmation modal
  const handleCloseConfirmationModal = () => {
    setcurrentActionData({
      confirmationMsg: "",
      apiUrl: "",
    });
    toggleConfirmationModal();
  };

  // handlePerformAction

  const handleAction = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(
        `${baseUrl}/${currentActionData.apiUrl}/${orderId}`
      );
      if (
        (res.status === 200 || res.status === 201 || res.status === 202) &&
        res.data.message
      ) {
        dispatch(openSnackbar(res.data.message, "success"));
        handleCloseConfirmationModal();
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
        handleCloseConfirmationModal();
      }
      getOrderRequestDetails();
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  // get oreder detail
  const getOrderRequestDetails = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `${baseUrl}/vendor/single/stock/request/${orderId}`
      );
      console.log("res==>", res.data.stockRequest);
      setOrderRequestDetails(res.data.stockRequest);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getOrderRequestDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | NFC | CHIPT </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" textTransform={"capitalize"} gutterBottom>
            Order Detail
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Order Info
              </Typography>
              <Stack>
                <Grid
                  container
                  columnGap={1}
                  rowGap={2}
                  display={"flex"}
                  justifyContent={"space-between"}
                  sx={{ position: "relative" }}
                >
                  <Grid item xs={12} sm={5.75} md={3.75}>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      variant="standard"
                      label="Vendor"
                      focused
                      value={orderRequestDetails?.vendor?.name || ""}
                      sx={{
                        "&>label": {
                          color: "black !important",
                        },
                      }}
                    />
                  </Grid>
                  {/*  */}
                  {/* <Box sx={{ position: "absolute", top: -20, right: 10 }}> */}
                  <Chip
                    sx={{ position: "absolute", top: -40, right: -10 }}
                    size="small"
                    label={orderRequestDetails?.requestStatus}
                    variant="filled"
                    color={
                      orderRequestDetails?.requestStatus === "PROCESSING" ||
                      orderRequestDetails.approvalStatus === "processing"
                        ? "warning"
                        : orderRequestDetails?.requestStatus === "DISPATCH" ||
                          orderRequestDetails.approvalStatus === "dispatch"
                        ? "info"
                        : orderRequestDetails?.requestStatus === "SHIPPED" ||
                          orderRequestDetails.approvalStatus === "shipped"
                        ? "primary"
                        : orderRequestDetails?.requestStatus === "DELIVERED" ||
                          orderRequestDetails.approvalStatus === "delivered"
                        ? "success"
                        : "error"
                    }
                  />
                  {/* </Box> */}
                  <Grid item xs={12} sm={5.75} md={3.75}>
                    <TextField
                      size="small"
                      fullWidth
                      variant="standard"
                      value={orderRequestDetails?.vendor?.email || ""}
                      sx={{
                        "&>label": {
                          color: "black !important",
                        },
                      }}
                      label="Email"
                      focused
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.75} md={3.75}>
                    <TextField
                      size="small"
                      fullWidth
                      variant="standard"
                      label="contact Number"
                      focused
                      disabled
                      value={
                        `(+${orderRequestDetails?.vendor?.countryCode})${orderRequestDetails?.vendor?.contactNumber}` ||
                        ""
                      }
                      sx={{
                        "&>label": {
                          color: "black !important",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack mt={2}>
                <TextField
                  variant="standard"
                  multiline
                  rows={1}
                  disabled
                  focused
                  fullWidth
                  size="small"
                  sx={{
                    "&>label": {
                      color: "black !important",
                    },
                    fontStyle: "italic",
                  }}
                  value={
                    `${orderRequestDetails?.shippingAddress?.plotnumber}, ${orderRequestDetails?.shippingAddress?.address} ${orderRequestDetails?.shippingAddress?.city} ${orderRequestDetails?.shippingAddress?.state} ${orderRequestDetails?.shippingAddress?.country} , ${orderRequestDetails?.shippingAddress?.zipCode}` ||
                    ""
                  }
                  label="Shipping Address"
                />
              </Stack>

              {orderRequestDetails.approvalStatus === "STATUS_PENDING" &&
              orderRequestDetails?.requestStatus === "PROCESSING" ? (
                <>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"start"}
                    direction={"row"}
                    spacing={1}
                    mt={2}
                  >
                    <Button
                      variant="contained"
                      sx={{ textTransform: "uppercase" }}
                      size="small"
                      color="primary"
                      onClick={() =>
                        handleOpenConfirmationMmodal(
                          "accept",
                          "vendor/accept/stock/request"
                        )
                      }
                    >
                      Accept Order
                    </Button>
                    <Button
                      sx={{ textTransform: "uppercase" }}
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() =>
                        handleOpenConfirmationMmodal(
                          "reject",
                          "vendor/reject/stock/request"
                        )
                      }
                    >
                      Reject Order
                    </Button>
                  </Stack>
                </>
              ) : orderRequestDetails.approvalStatus === "STATUS_ACCEPTED" &&
                orderRequestDetails?.requestStatus === "SHIPPED" ? (
                <>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "uppercase" }}
                    size="small"
                    color="success"
                    onClick={() =>
                      handleOpenConfirmationMmodal(
                        "deliver",
                        "vendor/stock/delivered"
                      )
                    }
                  >
                    Deliver
                  </Button>
                </>
              ) : (
                <></>
              )}
              {/* {orderRequestDetails.approvalStatus === "STATUS_PENDING" &&
              orderRequestDetails?.requestStatus !== "DELIVERED" ? (
                <Stack
                  mt={2}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"start"}
                  direction={"row"}
                >
                  {orderRequestDetails.approvalStatus === "STATUS_PENDING" ? (
                    <Button
                      variant="contained"
                      sx={{ textTransform: "uppercase" }}
                      size="small"
                      color="primary"
                      onClick={() =>
                        handleOpenConfirmationMmodal(
                          "accept",
                          "vendor/accept/stock/request"
                        )
                      }
                    >
                      Accept Order
                    </Button>
                  ) : (
                    ""
                  )}

                  {orderRequestDetails.approvalStatus === "STATUS_ACCEPTED" ? (
                    <Button
                      variant="contained"
                      sx={{ textTransform: "uppercase" }}
                      size="small"
                      color="success"
                      onClick={() =>
                        handleOpenConfirmationMmodal(
                          "deliver",
                          "vendor/stock/delivered"
                        )
                      }
                    >
                      Deliver
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button
                    sx={{ textTransform: "uppercase" }}
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() =>
                      handleOpenConfirmationMmodal(
                        "reject",
                        "vendor/reject/stock/request"
                      )
                    }
                  >
                    Reject Order
                  </Button>
                </Stack>
              ) : (
                ""
              )} */}
            </CardContent>
          </Card>
        </Stack>
        <Stack mt={2}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Item Details
              </Typography>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Index</TableCell>
                      <TableCell align="left">Cup Chipt Id</TableCell>
                      <TableCell align="right">Item Type</TableCell>
                      <TableCell align="right">Item Category</TableCell>
                      <TableCell align="right">Item Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderRequestDetails?.cupsRequested?.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>

                        <TableCell align="left">
                          {item?.cupModelUniqueId}
                        </TableCell>
                        <TableCell align="right">{item?.cupType}</TableCell>
                        <TableCell align="right">{item?.cupSize}</TableCell>
                        <TableCell align="right">
                          {item?.numberOfCups}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Stack>
      </Container>

      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleAction={handleAction}
        warningMsg={currentActionData.confirmationMsg}
        modalFor="order request"
      />
    </>
  );
};

export default SingleOrderRequestDetail;
