import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import Iconify from "../../../components/iconify/Iconify";
import { baseUrl } from "../../../utils/apiUrl";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

const SizeBasedCupInfoCard = ({ cupSizeInfo, dataReloadAction }) => {
  const dispatch = useDispatch();
  const [openConfirmationModal, setopenConfirmationModal] = useState(false);
  const [values, setvalues] = useState({ numberOfCups: null });
  const [isUpdated, setIsUpdated] = useState(false);
  const [edit, setedit] = useState(false);
  const [checked, setChecked] = React.useState(true);
  console.log(cupSizeInfo);
  // confirmation modal toggler
  const toggleConfirmationModal = () =>
    setopenConfirmationModal(!openConfirmationModal);

  // handleOpenModal

  const handleOpenModal = () => toggleConfirmationModal();

  // update state
  const handleChangeValues = (e) => {
    console.log("===>", e.target.value);
    setvalues({ ...values, [e.target.name]: e.target.value });
    setIsUpdated(true);
  };
  // submit data
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.put(
        `${baseUrl}/cup/${cupSizeInfo?._id}/details/update`,
        {
          cupPrice: values?.cupPrice,
          loyaltyPoints: values?.loyaltyPoints,
          numberOfCups:
            values?.numberOfCups !== null ? values?.numberOfCups * 1 : null,
          returnTime: values?.returnTime ? values?.returnTime : null,
        }
      );
      dispatch(openSnackbar("cup is updated successfully", "success"));

      setedit(false);
      setIsUpdated(false);
      dataReloadAction();

      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.messag, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  // update cup availability

  const handleCupAvailabilityAction = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `${baseUrl}/cup/${cupSizeInfo?._id}/availability/update`
      );
      dispatch(openSnackbar("updated successfully", "success"));
      dispatch(isLoading(false));
      dataReloadAction();
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.messag, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    if (cupSizeInfo) {
      // console.log("==>", cupSizeInfo?.points?.split("/")[0] * 1);
      console.log("==>", cupSizeInfo);

      setvalues({
        cupPrice: cupSizeInfo?.cupPrice,
        loyaltyPoints: cupSizeInfo?.points?.trim()?.split("/")[0] * 1,
        isCupAvailable: cupSizeInfo?.isCupAvailable,
        returnTime: cupSizeInfo?.returnTime,
        // numberOfCups: cupSizeInfo?.numberOfCups,
      });
    }
  }, [cupSizeInfo]);

  return (
    <>
      <Grid item xs={12} component={Paper} p={2}>
        <Stack
          direction={"row"}
          alignContent={"start"}
          justifyContent={"space-between"}
        >
          <Chip
            label={`${cupSizeInfo?.cupSize}`}
            variant="outlined"
            size="small"
            color="info"
          />
          <Button
            variant="text"
            color={edit ? "error" : "warning"}
            size="small"
            onClick={() => setedit(!edit)}
          >
            {edit ? "close" : "edit"}
          </Button>
        </Stack>
        <Stack
          spacing={2}
          // alignItems={"start"}

          component={"form"}
          onSubmit={handleSubmit}
        >
          <Grid container rowGap={1} columnGap={0.5}>
            <Grid item xs={12} md={2.75} lg={3.75}>
              <TextField
                name="cupPrice"
                label="Price"
                fullWidth
                type="number"
                required
                value={values?.cupPrice ? values?.cupPrice : ""}
                disabled={!edit}
                onChange={handleChangeValues}
                focused
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <Iconify icon="pepicons-pop:dollar" /> */}
                      <Typography
                        variant="body2"
                        fontStyle={"italic"}
                        component="span"
                      >
                        RM
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.75} lg={3.75}>
              <TextField
                name="loyaltyPoints"
                label="Loyalty Points"
                fullWidth
                // type="number"
                required
                disabled={!edit}
                value={values?.loyaltyPoints * 1}
                onChange={handleChangeValues}
                focused
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="ph:coins-fill" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.75} lg={3.75}>
              <TextField
                name="numberOfCups"
                label={`${cupSizeInfo.numberOfCups} Add More`}
                fullWidth
                type="number"
                // required
                value={values?.numberOfCups ? values?.numberOfCups : ""}
                disabled={!edit}
                onChange={handleChangeValues}
                placeholder="Wanted to add more cups"
                focused
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <Iconify icon="pepicons-pop:dollar" /> */}
                      <Iconify icon="tabler:numbers" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.75} lg={3.75}>
              <TextField
                name="returnTime"
                label={`Return Days`}
                fullWidth
                // type="number"
                // required
                value={values?.returnTime ? values?.returnTime : ""}
                disabled={!edit}
                onChange={handleChangeValues}
                placeholder="Return Days"
                focused
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <Iconify icon="pepicons-pop:dollar" /> */}
                      <Iconify icon="fluent:group-return-24-regular" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2.75} lg={3.75}>
              <Tooltip title="N/A refers to as 'NOT AVAILABLE', If true means unavailabe false confirms availability of cup">
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      checked={!values?.isCupAvailable}
                      onChange={handleOpenModal}
                      // disabled
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="N/A"
                  labelPlacement="start"
                />
              </Tooltip>
            </Grid>
          </Grid>

          <Stack alignItems={"start"}>
            {isUpdated ? (
              <Button type="submit" variant="contained">
                Update
              </Button>
            ) : (
              ""
            )}
          </Stack>
        </Stack>
      </Grid>
      <ConfirmationModal
        open={openConfirmationModal}
        handleAction={handleCupAvailabilityAction}
        handleClose={toggleConfirmationModal}
        warningMsg={`mark ${
          values?.isCupAvailable ? "unavailable" : "available"
        }`}
        modalFor="cup"
      />
    </>
  );
};

export default SizeBasedCupInfoCard;
