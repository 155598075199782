import {
  Box,
  Button,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";
import PackageTable from "./PackageTable";

const OrdersMainPage = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    price: "",
    freeCupCredits: "",
    totalCredits: "",
    isActive: true,
  });

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeForm = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(isLoading(true));
      const formattedFormValues = {
        ...formValues,
        price: Number(formValues.price),
        freeCupCredits: Number(formValues.freeCupCredits),
        totalCredits: Number(formValues.totalCredits),
      };
      const res = await axios.post(`${baseUrl}/package`, formattedFormValues);
      dispatch(isLoading(false));
      dispatch(openSnackbar("Package added successfully", "success"));
      navigate(-1);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Something went wrong";
      dispatch(openSnackbar(errorMessage, "error"));
      dispatch(isLoading(false));
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | NFC | CHIPT </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" textTransform={"capitalize"} gutterBottom>
            Package Management
          </Typography>
        </Stack>
        <Stack>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="All Packages" {...a11yProps(0)} />
                <Tab label="Add New Package" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <div
              role="tabpanel"
              hidden={value !== 0}
              id={`simple-tabpanel-0`}
              aria-labelledby={`simple-tab-0`}
            >
              {value === 0 && (
                <Box sx={{ p: 3 }}>
                  <PackageTable
                    type="all"
                    title="Current packages"
                    getApiUrl="/package"
                  />
                </Box>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={value !== 1}
              id={`simple-tabpanel-1`}
              aria-labelledby={`simple-tab-1`}
            >
              {value === 1 && (
                <Box sx={{ p: 3 }}>
                  <CardContent>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                      spacing={2}
                      mb={2}
                    >
                      <Typography variant="h4" sx={{ color: "grey" }}>
                        Add Package
                      </Typography>
                    </Stack>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        display={"flex"}
                        justifyContent={"space-between"}
                        rowGap={2.5}
                        columnGap={3}
                        mb={2}
                      >
                        <Grid item xs={12} md={5.5}>
                          <TextField
                            label="Package Name"
                            name="name"
                            fullWidth
                            required
                            value={formValues.name}
                            size="small"
                            onChange={handleChangeForm}
                            placeholder="Enter Package Name"
                          />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                          <TextField
                            label="Description"
                            name="description"
                            fullWidth
                            required
                            value={formValues.description}
                            size="small"
                            onChange={handleChangeForm}
                            placeholder="Enter Description"
                          />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                          <TextField
                            label="Price (in RM)"
                            name="price"
                            type="number"
                            fullWidth
                            required
                            value={formValues.price}
                            size="small"
                            onChange={handleChangeForm}
                            placeholder="Enter Price"
                          />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                          <TextField
                            label="Free Cup Credits"
                            name="freeCupCredits"
                            type="number"
                            fullWidth
                            required
                            value={formValues.freeCupCredits}
                            size="small"
                            onChange={handleChangeForm}
                            placeholder="Enter Free Cup Credits"
                          />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                          <TextField
                            label="Total Credits"
                            name="totalCredits"
                            type="number"
                            fullWidth
                            required
                            value={formValues.totalCredits}
                            size="small"
                            onChange={handleChangeForm}
                            placeholder="Enter Total Credits"
                          />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formValues.isActive}
                                onChange={handleChangeForm}
                                name="isActive"
                                color="primary"
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                      </Grid>
                      <Stack mt={2} direction={"row"}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Add Package
                        </Button>
                      </Stack>
                    </form>
                  </CardContent>
                </Box>
              )}
            </div>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default OrdersMainPage;
