import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Box, Button, Typography, Grid } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

// function to untie the complex array data json
function refactorCups(arrayOfArray) {
  const tempArray = [];
  arrayOfArray?.forEach((cupData) => {
    tempArray.push({
      rowIndex: tempArray.length + 1,
      cupSize: cupData.cupID?.cupSize,
      cupType: cupData.cupID?.cupType,
      cupModelUniqueId: cupData?.cupModelUniqueId,
      cupUniqueId: cupData?.cupUniqueId,
      cupID: cupData?.cupID?._id,
      _id: cupData?._id,
    });
  });
  return tempArray;
}

// function to desing customize tooltip
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const AllScannedAddedCupsTable = ({ reload }) => {
  const dispatch = useDispatch();

  const [allAddedCups, setAllAddedCups] = useState([]);

  const [openDeleteModal, setopenDeleteModal] = useState(false);

  const [targetId, setTargetId] = useState(null);

  const toggleOpenDeleteModal = () => setopenDeleteModal(!openDeleteModal);

  const handleOpenDeleteModal = (targetId) => {
    setTargetId(targetId);
    toggleOpenDeleteModal();
  };

  const handleCloseModal = () => {
    setTargetId(null);
    toggleOpenDeleteModal();
  };

  // customize filter tool
  function CustomizedToolbarLayout() {
    return (
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <Box width="100%" sx={{ p: 2 }}>
            <GridToolbarQuickFilter
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "rgba(158, 158, 158, 1)",
                  borderRadius: "10px",
                  borderColor: "rgba(158, 158, 158, 1)",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          <CustomToolbar />
        </Grid>
      </Grid>
    );
  }

  // custome toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "rowIndex",
      headerName: "ID",
      width: 80,
      sortable: false,
      filterable: false,
    },

    {
      field: "cupUniqueId",
      headerName: "Cup ID",
      width: 200,
      editable: false,
      filterable: false,
    },

    {
      field: "cupModelUniqueId",
      headerName: "Model ID",
      width: 160,
      editable: false,
    },

    {
      field: "cupSize",
      headerName: "Size",
      type: "sting",
      width: 110,
      editable: false,
    },

    {
      field: "cupType",
      headerName: "Type",
      description: "This column has a value getter and is not sortable.",
      //   sortable: false,
      width: 160,
    },

    {
      field: "action",
      headerName: "Destroy/Delete Cup",
      align: "center",
      description:
        "Once the cup is deleted or destroyed will not be able to add back",
      //   sortable: false,
      editable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <HtmlTooltip
              title={
                <>
                  <Typography color="inherit">
                    {params.row.cupUniqueId}
                  </Typography>
                  <em>Once it will be deleted</em>{" "}
                  <b style={{ color: "red" }}> will not be able to add back</b>{" "}
                </>
              }
              placement="bottom"
            >
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={() => handleOpenDeleteModal(params.row._id)}
                color="error"
              >
                Destroy
              </Button>
            </HtmlTooltip>
          </>
        );
      },
    },
  ];

  //   delete modal

  //   get all scanned cup
  const getAllScannedAddedCup = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${baseUrl}/cup/available/list`);
      console.log("==>", res);
      if (res.data.cups && res.data.cups.length > 0) {
        setAllAddedCups(res.data.cups);
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("Something went wrong", "error"));
      }
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  //   delete cup
  const handleDeleteCup = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.put(`${baseUrl}/cup/available/disable`, {
        cupID: targetId,
      });

      dispatch(isLoading(false));
      if ((res.status === 200 || res.status === 201) && res.data.message) {
        dispatch(openSnackbar(res.data.message, "success"));
        getAllScannedAddedCup();
      } else if ((res.status === 200 || res.status === 201) && res.message) {
        dispatch(openSnackbar(res.message, "success"));
        getAllScannedAddedCup();
      } else {
        dispatch(openSnackbar(res.message, "warning"));
        getAllScannedAddedCup();
      }
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("Something went wrong", "error"));
      }
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getAllScannedAddedCup();
  }, [reload]);

  const tempAvailableCupsArray =
    allAddedCups && allAddedCups.length > 0 ? refactorCups(allAddedCups) : [];

  console.log("tempdata==>", tempAvailableCupsArray);

  return (
    <>
      <Box sx={{ minHeight: "250px", maxHeight: "300px", width: "100%" }}>
        {tempAvailableCupsArray && tempAvailableCupsArray.length > 0 ? (
          <DataGrid
            rows={
              tempAvailableCupsArray && tempAvailableCupsArray.length > 0
                ? tempAvailableCupsArray
                : []
            }
            //   rows={rows}
            slots={{ toolbar: CustomizedToolbarLayout }}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 6,
                },
              },
            }}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                {
                  outline: "none",
                },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            // disableRowSelectionOnClick
          />
        ) : (
          "No cups added yet"
        )}
      </Box>
      <ConfirmationModal
        open={openDeleteModal}
        handleClose={handleCloseModal}
        modalType={"delete"}
        warningMsg={"delete"}
        modalFor={"cup"}
        handleAction={handleDeleteCup}
      />
    </>
  );
};

export default AllScannedAddedCupsTable;
