import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import { useNavigate } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};
const AvailableCupsCard = ({ cupDetail, reloadCupsDetails }) => {
  const navigate = useNavigate();
  return (
    <>
      <Card sx={{ minWidth: 275, position: "relative" }}>
        <CardContent>
          <Stack alignItems={"center"}>
            {/* <img
              src="/assets/images/products/mug.png"
              alt={cupDetail?.cupType}
              style={{
                maxHeight: "120px",
                maxWidth: "120px",
              }}
            /> */}
            <Box
              sx={{
                minHeight: "120px",
                minWidth: "120px",
                maxHeight: "120px",
                maxWidth: "120px",
                position: "relative",
                background: "url(./assets/images/products/mug.png)",
                backgroundSize: "100% 100%",
                // backgroundColor: "red",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "40%",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h6"
                  textTransform={"capitalize"}
                  fontFamily={"monospace"}
                >
                  {cupDetail?.cupType}
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Stack mt={2}>
            <Carousel
              swipeable={"false"}
              draggable={"false"}
              showDots={"true"}
              responsive={responsive}
              ssr={"true"} // means to
              infinite={"true"}
              //   autoPlay={responsive.deviceType !== "mobile"}
              autoPlaySpeed={1000}
              keyBoardControl={"true"}
              transitionDuration={1000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {cupDetail?.availableSize?.map((size, index) => {
                return (
                  <Stack key={index} pb={2} alignItems={"center"}>
                    {/* <Box sx={{ height: "50px", width: "50px" }}>
                      <img
                        src="/assets/images/products/mug.png"
                        alt="size"
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                    <Typography variant="body" component={"span"}>
                      {size?.cupSize}
                    </Typography> */}

                    <Typography
                      variant="h6"
                      textTransform={"capitalize"}
                      fontFamily={"monospace"}
                    >
                      <Typography
                        variant="body2"
                        component={"span"}
                        fontStyle={"italic"}
                      >
                        RM{" "}
                      </Typography>
                      {`${size?.cupPrice}`}
                    </Typography>
                    <Typography variant="body" component={"span"}>
                      {size?.cupSize}
                    </Typography>
                    <Typography
                      variant="body"
                      fontSize={"10px"}
                      component={"caption"}
                    >
                      {size?.cupCapacity}
                    </Typography>
                  </Stack>
                );
              })}
            </Carousel>
          </Stack>
        </CardContent>
        <Box sx={{ position: "absolute", top: -2, right: 5, zIndex: 300 }}>
          <Button
            variant="text"
            size="small"
            color="error"
            onClick={() =>
              navigate(`/cups/${cupDetail?.cupType}`, {
                state: { cupInfo: cupDetail },
              })
            }
          >
            View & update
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default AvailableCupsCard;
