import { DataGrid } from "@mui/x-data-grid";
import React from "react";

// function to set rows
function toGetRows(data) {
  const tempArray = [];
  data.forEach((el) => {
    tempArray.push({
      rowIndex: tempArray.length + 1,
      ...setColumnDefinition(el),
    });
  });
  return tempArray;
}

// function to set coulumsn definition
function setColumnDefinition(params) {
  const tempKeys = Object.keys(params);
  const tempColObject = {};
  let count = 0;
  tempKeys.forEach((e) => {
    tempColObject[`column${count + 1}`] = params[e];
    count += 1;
  });
  return tempColObject;
}

// define the dynamic headers
function setTableHeaders(rows) {
  console.log("==headRows", rows);
  const tempCols = Object.keys(rows[0])?.map((col, index) => {
    if (col === "rowIndex") {
      return {
        field: `rowIndex`,
        headerName: `ID`,
        width: 100,
        sortable: false,
        filterable: false,
      };
    }
    return {
      field: `column${index}`,
      headerName: `Column${index}`,
      width: 180,
      sortable: false,
    };
  });

  rows.forEach((e) => {
    if (Object.keys(e).length > tempCols.length) {
      Object.keys(e).forEach((el) => {
        tempCols.push({
          field: `column${tempCols.length}`,
          headerName: `Column${tempCols.length}`,
          width: 100,
        });
      });
    }
  });
  return tempCols;
}

const TableToShowBulkSelectedData = ({ data }) => {
  const rows = data ? toGetRows(data) : [];

  const columns =
    rows && rows.length > 0
      ? setTableHeaders(rows)
      : [
          {
            field: `rowIndex`,
            headerName: `ID`,
            width: 100,
          },
        ];

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.rowIndex}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          //   checkboxSelection
        />
      </div>
    </>
  );
};

export default TableToShowBulkSelectedData;
