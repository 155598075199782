import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Iconify from "../../../components/iconify/Iconify";
import SizeBasedCupInfoCard from "./SizeBasedCupInfoCard";
import { isLoading } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";

const CupDetailPage = () => {
  const { cupModel } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cupsDetail, setCupDetail] = useState([]);

  const getAllCupsOfModel = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `${baseUrl}/cup/all/in/inventory?keyword=${cupModel}`
      );
      setCupDetail(res?.data?.cups[0]);

      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    if (cupModel) {
      getAllCupsOfModel();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Cup Detail | NFC</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" textTransform={"capitalize"} gutterBottom>
            Cup Detail
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Grid
          container
          rowGap={2}
          columnGap={1}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"start"}
        >
          <Grid item xs={12} sm={5.75} md={3.75}>
            <Stack p={2} alignItems={"center"}>
              <Box
                component="img"
                src="../assets/images/products/mug.png"
                // sx={{ background: "url(/assets/images/products/mug.png)" }}
                alt="cupimage"
                width={"100%"}
                maxWidth={"120px"}
              />
              {/* <img src="../assets/images/products/mug.png" alt="cupimage" /> */}
              <Typography
                variant="h6"
                textTransform={"capitalize"}
                component={"div"}
                color={"text.primary"}
              >
                {cupsDetail?.cupType}
              </Typography>
            </Stack>
            <Typography variant="h5" color={"primary"}>
              Availables
            </Typography>
            <Divider />
            <Grid container mt={1} rowGap={1.2}>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"row"}
                p={0.5}
              >
                <Typography
                  variant="caption"
                  component={"b"}
                  // color={"text.secondary"}
                  fontWeight={"bold"}
                >
                  Size
                </Typography>
                <Typography
                  variant="caption"
                  component={"b"}
                  fontWeight={"bold"}
                  // color={"text.secondary"}
                >
                  Total Cups
                </Typography>
              </Grid>
              {/* {state?.cupInfo?.cupType} */}
              {cupsDetail?.availableSize?.map((size, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                    flexDirection={"row"}
                    border={"0.5px solid lightgrey"}
                    p={0.5}
                    borderRadius={"10px"}
                    bgcolor={"success.dark"}
                  >
                    <Typography
                      variant="body2"
                      component={"div"}
                      // color={"text.secondary"}
                      color={"white"}
                    >
                      {size?.cupSize}
                    </Typography>
                    <Typography
                      variant="body2"
                      component={"div"}
                      color={"white"}
                      // color={"text.secondary"}
                    >
                      {`${size?.numberOfCups}`}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid
            item
            container
            rowGap={1.5}
            p={1}
            xs={12}
            sm={5.75}
            maxHeight={"480px"}
            overflow={"auto"}
            md={7.75}
            sx={{
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                marginLeft: "20px",
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
            }}
          >
            <Grid item xs={12}>
              <Typography>Cup Info</Typography>
            </Grid>
            {cupsDetail?.availableSize?.map((cup, itemKey) => {
              return (
                <SizeBasedCupInfoCard
                  key={itemKey}
                  cupSizeInfo={cup}
                  dataReloadAction={getAllCupsOfModel}
                />
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CupDetailPage;
