import {
  Avatar,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const SingleVendorStockCard = ({ index, cups }) => {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={5.75}
        boxShadow={
          "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)"
        }
        component={Paper}
      >
        <Stack p={1} alignItems={"center"}>
          <Avatar
            src="/assets/images/products/cup.png"
            alt="New"
            sx={{
              // bgcolor: "rgba(237,239,241,1)"
              bgcolor: "lightgray",
            }}
          />
          <Typography variant="h6" fontFamily={"monospace"} color={"secondary"}>
            {cups?.cupType}
          </Typography>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Size</TableCell>
                  <TableCell align="right">Price ($/cup)</TableCell>
                  <TableCell align="right">Number Of Cups</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {cups?.sizes?.map((info, key) => {
                  return (
                    <TableRow key={key} hover role="checkbox" tabIndex={-1}>
                      <TableCell align={"left"}>{info?.cupSize}</TableCell>
                      <TableCell
                        align={"right"}
                      >{`$ ${info?.cupPrice}`}</TableCell>
                      <TableCell align={"right"}>
                        {info?.numberOfCups}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Grid>
    </>
  );
};

export default SingleVendorStockCard;
