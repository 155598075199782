// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCQjMnEXHaxNJAKWjuHqhqlN1d-UxYZJ08",
//   authDomain: "chipt-nfc.firebaseapp.com",
//   projectId: "chipt-nfc",
//   storageBucket: "chipt-nfc.appspot.com",
//   messagingSenderId: "1064247848737",
//   appId: "1:1064247848737:web:1d301ed3e44c3b24189860",
//   measurementId: "G-19CSFGFXQX"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCHQoDdiO7kC5SfWDHJjFy-fOgVxYkEoLw",
  authDomain: "chipt-nfc-515e6.firebaseapp.com",
  projectId: "chipt-nfc-515e6",
  storageBucket: "chipt-nfc-515e6.appspot.com",
  messagingSenderId: "924205504603",
  appId: "1:924205504603:web:abb5e9809fd1eed761877e",
  measurementId: "G-FE65B991LG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Cloud Messaging and get a reference to the service
export default app;
