import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch } from "react-redux";

import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import AvailableCupsCard from "./AvailableCupsCard";
import { baseUrl } from "../../../utils/apiUrl";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

const CupsMgtHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cupsArray, setCupsArray] = useState([]);

  const getAllCups = async () => {
    dispatch(isLoading(true));
    try { 
      const res = await axios.get(`${baseUrl}/cup/all/in/inventory`);
      console.log("data==>",res)
      if (res.status === 200 && res.data.cups && res.data.cups.length > 0) {
        setCupsArray(res?.data?.cups);
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar(res.data.message, "warning"));
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };
  useEffect(() => {
    getAllCups();
  }, []);

  return (
    <>
      <Helmet>
        <title> Manage Cup | NFC | CHIPT </title>
      </Helmet>
      <Container>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent={{ xs: "start", sm: "space-between" }}
          pb={2}
        >
          <Typography
            variant="h4"
            sx={{ textAlign: { xs: "center", sm: "start" } }}
            textTransform={"capitalize"}
            gutterBottom
          >
            Cup Inventory Management System
          </Typography>

          <Stack
            spacing={1}
            direction={{ xs: "column", sm: "row" }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/scan-to-add-cup")}
              startIcon={<DocumentScannerIcon />}
            >
              Scan & Add Cup
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/cups/add")}
              startIcon={<AddIcon />}
            >
              Add Cups Inventory
            </Button>
          </Stack>
        </Stack>
        <Stack>
          {cupsArray.length > 0 ? (
            <Grid
              container
              rowGap={2}
              columnGap={2}
              display={"flex"}
              flexDirection={"row"}
            >
              {cupsArray?.map((cup, index) => {
                return (
                  <Grid item key={index} xs={12} sm={5.75} md={3.75}>
                    <AvailableCupsCard
                      cupDetail={cup}
                      reloadCupsDetails={getAllCups}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            "No Data Found"
          )}
        </Stack>
      </Container>
    </>
  );
};

export default CupsMgtHome;
