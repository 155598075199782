import React, { useRef, useState, useEffect, forwardRef } from "react";
import "../../../index.css";
import jsQR from "jsqr";
import Webcam from "react-webcam";
import {
  Button,
  Stack,
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const QrCodeScanner = ({
  isScanned,
  setIsScanned,
  cupDetail,
  setCupDetail,
  scrollToTop,
}) => {
  const [scannedQr, setScannedQr] = useState(null);
  const webcamRef = useRef(null);
  const [toggleCamera, setToggleCamera] = useState(false);
  const [open, setOpen] = React.useState(false);

  //   functiont to start scan
  const handleStartScan = () => {
    setToggleCamera(true);
  };

  // function to stop scan
  const handleStopScan = () => {
    setToggleCamera(false);
  };

  //

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Function to decode QR code from the current webcam frame
    const decodeQRCode = () => {
      // Ensure webcam reference and video element are available
      if (webcamRef.current && webcamRef.current.video.readyState === 4) {
        // Capture current frame from webcam
        const imageData = captureFrame();
        // Attempt to decode QR code from captured frame
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        // If QR code is detected, update state with code data
        if (code) {
          setScannedQr(code.data);
          setCupDetail({ ...cupDetail, cupID: `${code.data}` });
          handleStopScan();
          setIsScanned(true);
          scrollToTop();
          handleClose();
        }
      }
    };

    // Function to capture current frame from webcam
    const captureFrame = () => {
      const video = webcamRef.current.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      return ctx.getImageData(0, 0, canvas.width, canvas.height);
    };

    // Set interval to continuously decode QR code from webcam feed
    const intervalId = setInterval(decodeQRCode, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <h3>QR Code Scanner</h3>

      {toggleCamera ? (
        <Stack
          alignItems="center"
          justifyContent={"center"}
          className="qr-scanner"
        >
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: "environment", // Set to "user" for front-facing camera
            }}
          />
        </Stack>
      ) : (
        <>
          <Box
            width={"100%"}
            maxWidth={"350px"}
            sx={{ cursor: "pointer" }}
            // onClick={handleStartScan}
            onClick={handleClickOpen}
          >
            <img width={"100%"} src="./assets/scanGIF.gif" alt="QR" />
          </Box>
        </>
      )}

      {scannedQr && <p>Scanned QR code: {scannedQr}</p>}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <Stack mt={2} alignItems={"center"} justifyContent={"center"}>
          <Stack
            alignItems="center"
            justifyContent={"center"}
            className="qr-scanner"
          >
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                facingMode: "environment", // Set to "user" for front-facing camera
              }}
            />
          </Stack>
          <Stack mt={2}>
            <Button variant="contained" onClick={handleClose} color="error">
              close
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default QrCodeScanner;
