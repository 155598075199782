import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

// components
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Label from "../../../components/label/Label";

import { isLoading } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";

// mock

function refactorPackageList(packageRows) {
  const tempArray = [];
  packageRows.forEach((packageData, index) => {
    tempArray.push({
      rowIndex: index + 1,
      ...packageData,
    });
  });
  return tempArray;
}

// -----------------------------------------
// customize filter tool
function CustomizedToolbarLayout() {
  return (
    <Grid container alignItems={"center"}>
      <Grid item xs={12} order={{ xs: 1, sm: 0 }} sm={6}>
        <Box width="100%" sx={{ p: 2 }}>
          <GridToolbarQuickFilter
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "rgba(158, 158, 158, 1)",
                borderRadius: "10px",
                borderColor: "rgba(158, 158, 158, 1)",
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} display={"flex"} justifyContent={"end"}>
        <CustomToolbar />
      </Grid>
    </Grid>
  );
}
// custom toolbar
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}
// -----------------------------------------

const PackageTable = ({ title, getApiUrl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);

  const allRowDataIncludingIndex =
    packages && packages.length > 0 ? refactorPackageList(packages) : [];

  console.log("===>", allRowDataIncludingIndex);

  // column definition

  const columns = [
    { field: "rowIndex", filterable: false, headerName: "ID", width: 90 },
    { field: "name", headerName: "Package Name", width: 180, editable: false },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price (in RM)",
      width: 100,
      editable: false,
    },
    {
      field: "freeCupCredits",
      headerName: "Free Cup Credits",
      width: 150,
      editable: false,
    },
    {
      field: "totalCredits",
      headerName: "Total Credits",
      width: 150,
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Label color={params.row.isActive ? "success" : "error"}>
          {params.row.isActive ? "Active" : "Inactive"}
        </Label>
      ),
    },
    {
      field: "update",
      filterable: false,
      headerName: "Update",
      description: "You can view and update the package",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => navigate(`/packages/${params.row._id}`)}
        >
          Update
        </Button>
      ),
    },
  ];

  // fetch data
  const getAllPackages = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}${getApiUrl}`);
      console.log("packages list res==>", res);
      setPackages(res?.data?.data);
      dispatch(isLoading(false));
    } catch (error) {
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };
  useEffect(() => {
    getAllPackages();
  }, []);

  return (
    <>
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/packages/add")}
          startIcon={<AddIcon />}
        >
          Add Packages
        </Button>
      </Stack>
      {allRowDataIncludingIndex && allRowDataIncludingIndex.length > 0 ? (
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={
              allRowDataIncludingIndex.length > 0
                ? allRowDataIncludingIndex
                : []
            }
            columns={columns}
            getRowId={(row) => row._id}
            slots={{ toolbar: CustomizedToolbarLayout }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            sx={{
              textTransform: "capitalize",
              minHeight: "250px",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
      ) : (
        <>
          <Label color={"error"}>No data found</Label>
        </>
      )}
    </>
  );
};

export default PackageTable;
