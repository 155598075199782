import { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import axios from "axios";
import { useDispatch } from "react-redux";
import Header from "./header";
import Nav from "./nav";
import { baseUrl } from "../../utils/apiUrl";
import { isLoading } from "../../redux/action/defaultActions";
import { adminProfileDataUpdate } from "../../redux/action/adminActions";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const getAdminProfileData = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/account/profile/details`);
      // account/profile/details
      console.log("res==>", res?.data?.admin);
      dispatch(
        adminProfileDataUpdate({
          ...res?.data?.admin,
        })
      );

      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.log({ error });
    }
  };
  useEffect(() => {
    getAdminProfileData();
  }, []);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
