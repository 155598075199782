import {
  Box,
  Button,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../utils/apiUrl";
import Iconify from "../../../components/iconify";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

// function to get the cupsizes
// function selectedCupSizes(objectArray, modelId) {
//   let cupModel = null;
//   objectArray.forEach((cupsize) => {
//     if (cupsize.modelId === modelId) {
//       cupModel = cupsize.name;
//     }
//   });
//   return cupModel;
// }

// function to return the cupsizes array to dipaly availabe cupsizes
function cupSizesArray(cupType, availableCupsArray) {
  let cupSizes = [];
  availableCupsArray.forEach((availableCup) => {
    if (availableCup[0] === cupType) {
      cupSizes = availableCup[1];
    }
  });
  return [...cupSizes];
}

const AddNewCup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [availableCups, setAvailableCups] = useState([]);
  const [file, setfile] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [cupValues, setCupValues] = useState({ cupPrice: null });
  // onchange event

  const handleChangecupValues = (e) => {
    console.log(e.target.value);
    if (e.target.name === "cupType") {
      setCupValues({
        cupType: e.target.value,
        cupSize: null,
        cupPrice: null,
        cupCapacity: null,
        numberOfCups: null,
      });
    } else {
      setCupValues({ ...cupValues, [e.target.name]: e.target.value });
    }
  };
  // handlefile change ==============
  const handleFileChange = (e) => {
    setfile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };
  // ====submit function ============
  const handleSubmitCupInfo = async (e) => {
    e.preventDefault();

    const formCupDetails = new FormData();

    formCupDetails.append("cupType", cupValues?.cupType);
    formCupDetails.append("cupSize", cupValues?.cupSize);
    formCupDetails.append("cupCapacity", cupValues?.cupCapacity);
    formCupDetails.append("cupPrice", cupValues?.cupPrice);
    formCupDetails.append("currency", cupValues?.currency);
    formCupDetails.append("numberOfCups", cupValues?.numberOfCups);
    formCupDetails.append("file", file);

    try {
      dispatch(isLoading(true));
      const res = await axios.post(
        `${baseUrl}/cup/details/upload`,
        formCupDetails
      );
      dispatch(isLoading(false));
      // navigate(-1);
      dispatch(openSnackbar("cup is added to inventory", "success"));
    } catch (error) {
      if (error.response.data.message) {
        // error.response.data.message
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  // get generated cup list

  const getGeneratedCupList = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${baseUrl}/cup/available/cup/csv`);
      console.log("cups==>", res);
      if (
        res.status === 200 &&
        res.data.cupAvailable &&
        res.data.cupAvailable?.length > 0
      ) {
        console.log("===>", res.data.cupAvailable);
        setAvailableCups(res.data.cupAvailable);
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar(res?.data?.message, "warning"));
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      console.log("error=>", error);
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getGeneratedCupList();
  }, []);

  return (
    <>
      <Helmet>
        <title> Add Cup | NFC | CHIPT </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" textTransform={"capitalize"} gutterBottom>
            Add Cups Inventory
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        {availableCups?.length > 0 ? (
          <CardContent>
            <Typography variant="h4" sx={{ color: "grey" }} mb={1}>
              Cup Info <Iconify icon="mingcute:information-fill" />{" "}
            </Typography>
            <form
              onSubmit={
                file && file != null
                  ? (e) => handleSubmitCupInfo(e)
                  : (e) => {
                      e.preventDefault();
                      dispatch(
                        openSnackbar("Please select an image", "warning")
                      );
                    }
              }
            >
              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                rowGap={2.5}
                columnGap={3}
                mb={2}
              >
                <Grid item xs={12} md={5.5}>
                  {/* <TextField
                  name="cupType"
                  required
                  fullWidth
                  label="Cup Type"
                  value={cupValues?.cupType ? cupValues?.cupType : ""}
                  onChange={handleChangecupValues}
                  size="small"
                  placeholder="Enter Cup Modal Type"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="solar:cup-paper-broken" />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                  <TextField
                    label="select cup type"
                    name="cupType"
                    select
                    fullWidth
                    // disabled={csvIsReady}
                    required
                    value={cupValues?.cupType ? cupValues?.cupType : ""}
                    size="small"
                    onChange={handleChangecupValues}
                    placeholder="will be assigned automatically"
                    sx={{ textTransform: "capitalize" }}
                    // focused
                  >
                    <MenuItem disabled value="0">
                      -- please select a cuptype --
                    </MenuItem>
                    {/* <MenuItem value="classic">royal</MenuItem>
                  <MenuItem value="premium">premium</MenuItem>
                  <MenuItem value="normal">normal</MenuItem> */}
                    {availableCups?.map((type, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={type[0]}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {type[0]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  {/* <TextField
                  fullWidth
                  required
                  name="cupSize"
                  type="text"
                  label="Cup Size"
                  size="small"
                  value={cupValues?.cupSize ? cupValues?.cupSize : ""}
                  onChange={handleChangecupValues}
                  placeholder="Enter Size of Cup"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="arcticons:bbc-bitesize" />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                  <TextField
                    label="select cup size"
                    name="cupSize"
                    select
                    fullWidth
                    // disabled={csvIsReady}
                    required
                    value={cupValues?.cupSize ? cupValues?.cupSize : ""}
                    size="small"
                    // onChange={(e) => handleChangeRowValues(e, index)}
                    onChange={handleChangecupValues}
                    placeholder="Select a Size of Cup"
                    sx={{ textTransform: "capitalize" }}
                    // focused
                  >
                    <MenuItem disabled value="0">
                      -- select cupsize --
                    </MenuItem>

                    {cupSizesArray(cupValues.cupType, availableCups)?.map(
                      (size, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={size.cupSize}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {size.cupSize}
                          </MenuItem>
                        );
                      }
                    )}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="cupPrice"
                    type="number"
                    label="Cup Price"
                    size="small"
                    value={cupValues?.cupPrice ? cupValues?.cupPrice : ""}
                    onChange={handleChangecupValues}
                    placeholder="Enter Cup Price"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {/* <Iconify icon="solar:dollar-line-duotone" /> */}
                          <Typography
                            variant="body2"
                            fontStyle={"italic"}
                            component="span"
                          >
                            RM
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="cupCapacity"
                    type="text"
                    label="Cup Capcity (in ml)"
                    size="small"
                    value={cupValues?.cupCapacity ? cupValues?.cupCapacity : ""}
                    onChange={handleChangecupValues}
                    placeholder="Enter Cup Capacity"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="carbon:ibm-openshift-container-platform-on-vpc-for-regulated-industries" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="numberOfCups"
                    type="text"
                    label="Number Of Cups"
                    size="small"
                    value={
                      cupValues?.numberOfCups ? cupValues?.numberOfCups : ""
                    }
                    onChange={handleChangecupValues}
                    placeholder="Enter Size of Cup"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="tabler:numbers" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="currency"
                    type="text"
                    label="Currency"
                    size="small"
                    value={cupValues?.currency ? cupValues?.currency : ""}
                    onChange={handleChangecupValues}
                    placeholder="Enter Curreny type"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {/* <Iconify icon="ri:currency-line" /> */}

                          <Iconify icon="grommet-icons:currency" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    component={"label"}
                    htmlFor="icon-button-photo"
                    endIcon={<Iconify icon="ri:upload-cloud-line" />}
                  >
                    {file ? file?.name.substring(0, 20) : "choose Image"}
                    <input
                      accept="image/*"
                      id="icon-button-photo"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      type="file"
                    />
                  </Button>
                  <Stack mt={2}>
                    {selectedImage && file ? (
                      <img
                        src={selectedImage}
                        alt={file?.name}
                        width={"100px"}
                        height={"100px"}
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Stack mt={2} direction={"row"}>
                <Button type="submit" variant="contained" color="primary">
                  Add To Inventory
                </Button>
              </Stack>
            </form>
          </CardContent>
        ) : (
          "There are no cups to be add"
        )}
      </Container>
    </>
  );
};

export default AddNewCup;
