import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import CustomersDetails from "./CustomersDetails";

const CustomerMainPage = () => {
  const [value, setValue] = React.useState(0);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title> Admin | All Customers </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          // mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
          {/* <Button
            variant="contained"
            onClick={() => navigate("/vendor/create")}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Vendor
          </Button> */}
        </Stack>
        <Stack>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="All Customers" {...a11yProps(0)} />
                <Tab label="Active Customers" {...a11yProps(1)} />
                <Tab label="Inactive Customers" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <div
              role="tabpanel"
              hidden={value !== 0}
              id={`simple-tabpanel-0`}
              aria-labelledby={`simple-tab-0`}
              //   {...other}
            >
              {value === 0 && (
                <Box>
                  <CustomersDetails
                    title="All Customers"
                    getApiRoute={"/customer/all"}
                  />
                </Box>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={value !== 1}
              id={`simple-tabpanel-1`}
              aria-labelledby={`simple-tab-1`}
            >
              {value === 1 && (
                <Box>
                  <CustomersDetails
                    title={"Active Customers"}
                    getApiRoute={"/customer/all/account/active"}
                  />
                </Box>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={value !== 2}
              id={`simple-tabpanel-2`}
              aria-labelledby={`simple-tab-2`}
              //   {...other}
            >
              {value === 2 && (
                <Box>
                  <CustomersDetails
                    title="In Active Customers"
                    getApiRoute={"/customer/all/account/inactive"}
                  />
                </Box>
              )}
            </div>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default CustomerMainPage;
