import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import Iconify from "../iconify";
import { openSnackbar } from "../../redux/action/defaultActions";

const ChangePasswordModal = ({ open, handleClose, handleAction }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid",
    borderColor: "info.dark",
    boxShadow: 24,
    p: 2,
  };
  const dispatch = useDispatch();
  const [values, setvalues] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const handleChangeValues = (e) =>
    setvalues({ ...values, [e.target.name]: e.target.value });

  // ==============hadndleSubmit========
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (
        values?.newpassword === values?.confirmpassword &&
        values?.oldpassword !== ""
      ) {
        handleClose();
        await handleAction(values);
      } else {
        dispatch(openSnackbar("Confirm Password Mismatch", "error"));
      }
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            alignItems={"center"}
            justifyContent={"space-between"}
            direction={"row"}
          >
            <Typography variant="h5" color={"primary"}>
              Change Password
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <Iconify icon="gg:close-o" />
            </IconButton>
          </Stack>
          <Stack py={1}>
            <Stack
              onSubmit={handleSubmit}
              component={"form"}
              mt={2}
              //   alignItems={"center"}
              spacing={2}
            >
              <TextField
                label="Old Password"
                focused
                type="password"
                value={values?.oldpassword ? values?.oldpassword : ""}
                size="small"
                name="oldpassword"
                onChange={handleChangeValues}
                placeholder="Enter Old Password"
                required
              />
              <TextField
                label="New Password"
                focused
                type="password"
                value={values?.newpassword ? values?.newpassword : ""}
                size="small"
                name="newpassword"
                onChange={handleChangeValues}
                placeholder="Create New Password"
                required
              />
              <TextField
                label="Confirm Password"
                focused
                value={values?.confirmpassword ? values?.confirmpassword : ""}
                size="small"
                name="confirmpassword"
                type={showPassword ? "text" : "password"}
                onChange={handleChangeValues}
                placeholder="Confirm New Password"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button type="submit" variant="contained">
                Change Password
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
