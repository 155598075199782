import {
  Avatar,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch } from "react-redux";

import axios from "axios";
import Iconify from "../../../components/iconify/Iconify";
import { isLoading } from "../../../redux/action/defaultActions";

import { baseUrl } from "../../../utils/apiUrl";

const CustomerProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [customerProfileData, setCustomerProfileData] = useState(null);

  useEffect(() => {
    // get customerProfileData
    const getCustomerProfleData = async () => {
      dispatch(isLoading(true));
      try {
        const res = await axios.get(
          `${baseUrl}/customer/profile/${customerId}`
        );
        // /admin/customer/profile/652fb07003b6f6b59adc5ded
        console.log("res==>", res);
        setCustomerProfileData(res?.data?.customers);
        dispatch(isLoading(false));
      } catch (error) {
        console.log("error=>", error);
        dispatch(isLoading(false));
      }
    };
    if (customerId) {
      getCustomerProfleData();
    }
  }, [customerId, dispatch]);

  return (
    <>
      <Helmet>
        <title> Admin | Customer Profile </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" textTransform={"capitalize"} gutterBottom>
            {customerProfileData?.name}
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Grid
          container
          spacing={1}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"start"}
        >
          <Grid
            item
            xs={12}
            md={7.5}
            container
            component={Paper}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"start"}
            sx={{ bgcolor: "rgba(232,235,238,1)" }}
          >
            <Grid item xs={12} md={4.75} p={0.2}>
              <Stack p={1}>
                <img
                  src={customerProfileData?.profilePicture?.url}
                  alt={customerProfileData?.name}
                />
                <Typography variant="body2" component={"p"}>
                  {customerProfileData?.location}
                </Typography>
                {/* <Box mt={2} component={"address"}>
                  We must optimize our processes, implementation new systems ,
                  learn to adapt,it's worth the effort in the end
                </Box> */}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6.75}>
              <Stack p={1} spacing={2}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <List
                    sx={{
                      width: "100%",
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "rgba(242,85,120,1)" }}>
                          <Iconify icon="ph:user" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Name"
                        sx={{
                          wordWrap: "break-word",
                          textTransform: "capitalize",
                        }}
                        secondary={customerProfileData?.name}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "rgba(80,150,254,1)" }}>
                          <Iconify icon="typcn:phone" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Phone"
                        secondary={customerProfileData?.primaryContactNumber}
                      />
                    </ListItem>
                    {customerProfileData?.primaryEmail && (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: "rgba(80,150,254,1)" }}>
                            <Iconify icon="dashicons:email" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Email"
                          secondary={customerProfileData?.primaryEmail}
                        />
                      </ListItem>
                    )}
                  </List>
                </Box>
                <Box mt={2} component={"address"}>
                  {customerProfileData?.location}
                </Box>
              </Stack>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(4)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  "Espresso Medium, $20 at Hazelnut",
                  "Cappacino Premium, $10 at Coffee house",
                  "American Coffee large, $100 at Woodpecker coffe house",
                  "Turkish Coffee small, $15 at Coffee Trees house",
                  "Western Coffee large, $18 at Cofee House",
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default CustomerProfilePage;
