import React, { useEffect, useState } from "react";
import {
  Stack,
  Paper,
  Avatar,
  Button,
  Switch,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// components
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

// components
import axios from "axios";
import { useDispatch } from "react-redux";

import ConfirmationModal from "../../../components/modal/ConfirmationModal";

import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";
import Label from "../../../components/label/Label";

// --------------------------------------------------------------------
// ----------------------------------------------------------------------

const VendorDetails = ({ title, getApiRoute }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [allVendors, setAllVendors] = useState([]);

  const [open, setOpen] = useState(null);

  const [openConfirmationModal, setopenConfirmationModal] = useState(false);

  const [activeId, setactiveId] = useState(null);

  const [confirmationMsg, setConfirmationMsg] = useState("");

  const handleCloseConfirmationModal = () => {
    setactiveId(null);
    setopenConfirmationModal(false);
    setConfirmationMsg("");
  };

  const handleOpenConfirmationModal = () => setopenConfirmationModal(true);

  const toggleConfirmationModal = () =>
    setopenConfirmationModal(!openConfirmationModal);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const getVendorsList = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}${getApiRoute}`);

      if (
        res.status === 200 &&
        res.data.vendors &&
        res.data.vendors.length > 0
      ) {
        setAllVendors(res?.data?.vendors);
        dispatch(isLoading(false));
      } else {
        if (res.data.message) {
          dispatch(openSnackbar(res.data.message, "error"));
        } else {
          dispatch(openSnackbar("No data found", "error"));
        }
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      }
      if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(
          openSnackbar(
            "something went worng, please contact the to tech support",
            "error"
          )
        );
      }
      console.log({ error });
      dispatch(isLoading(false));
    }
  };

  // ===========handleblock user ===
  const handleBlockOpenModal = (id, actionType) => {
    setactiveId(id);
    setConfirmationMsg(actionType ? "block" : "unblock");
    setopenConfirmationModal(true);
  };

  const handleBlock = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(
        `${baseUrl}/vendor/account/block/unblock/${activeId}`
      );
      handleCloseConfirmationModal();
      dispatch(openSnackbar("user status updated", "success"));
      dispatch(isLoading(false));
      getVendorsList();
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      handleCloseConfirmationModal();
      dispatch(isLoading(false));
    }
  };
  // ===============================
  useEffect(() => {
    if (getApiRoute) {
      getVendorsList();
    }
  }, []);

  // customize filter tool
  function CustomizedToolbarLayout() {
    return (
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <Box width="100%" sx={{ p: 2 }}>
            <GridToolbarQuickFilter
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "rgba(158, 158, 158, 1)",
                  borderRadius: "10px",
                  borderColor: "rgba(158, 158, 158, 1)",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          <CustomToolbar />
        </Grid>
      </Grid>
    );
  }

  // custome toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }
  // rows definition
  const columns = [
    { field: "index", headerName: "Sr No.", width: 90 },
    {
      field: "name",
      headerName: "Vendor",
      width: 220,
      renderCell: (params) => {
        return (
          <>
            <Avatar
              alt={params?.row?.name}
              src={params?.row?.profilePicture?.url}
            />
            &nbsp;&nbsp; &nbsp;&nbsp;
            <Typography variant="subtitle2" noWrap>
              {params?.row?.name}
            </Typography>
          </>
        );
      },
    },
    { field: "primaryEmail", headerName: "Email", width: 250 },
    // { field: "username", headerName: "Username", width: 180 },
    {
      field: "accountVerified",
      headerName: "Verified",
      width: 120,
      renderCell: (params) => {
        return <> {params?.row?.accountVerified ? "Yes" : "No"}</>;
      },
    },
    {
      field: "status",
      headerName: "status",
      width: 120,
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Label
              color={
                (params?.row?.accountActive === false && "error") || "success"
              }
            >
              {params?.row?.accountActive ? "active" : "inactive"}
            </Label>
          </>
        );
      },
    },
    {
      field: "blocked",
      headerName: "Blocked",
      width: 120,
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Switch
              checked={!params?.row?.accountActive}
              disabled={!params?.row?.accountVerified}
              onChange={() =>
                handleBlockOpenModal(
                  params?.row?._id,
                  params?.row?.accountActive
                )
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
      },
    },
    {
      field: "preview",
      headerName: "Preview",
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() =>
                navigate(`/vendor/vendorprofile/${params?.row?._id}`)
              }
            >
              View
            </Button>
          </>
        );
      },
    },
  ];

  //
  const allRowDataIncludingIndex =
    allVendors &&
    allVendors?.map((row, index) => ({
      ...row,
      index: index + 1,
    }));

  return (
    <>
      <Stack>
        <Typography variant="h4">{title}</Typography>
      </Stack>
      {allVendors.length > 0 ? (
        <Box sx={{ minHeight: 400, width: "100%" }} >
          <DataGrid
            rows={allRowDataIncludingIndex}
            columns={columns}
            getRowId={(row) => row?._id}
            slots={{ toolbar: CustomizedToolbarLayout }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            sx={{
              minHeight: "250px",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      ) : (
        "No Data Found"
      )}
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleAction={handleBlock}
        warningMsg={confirmationMsg}
        modalFor="vendor"
      />
    </>
  );
};

export default VendorDetails;
