import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const VendorStockInfoCard = ({ index, data }) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        item
        container
        alignItems={"center"}
        onClick={() => navigate(`/vendor-stock/single-vendor-stock/${data?.vendorId}`)}
        p={1}
        xs={12}
        //   bgcolor={
        //     index % 2 === 0
        //       ? "primary.light"
        //       : index % 3 === 0
        //       ? "secondary.light"
        //       : "info.light"
        //   }
        sx={{
          border: "0.5px solid lightgrey",
          borderLeft: "4px solid",
          cursor: "pointer",
          transition: "ease-in all 300ms",
          "&:hover": {
            bgcolor: "rgba(237,239,241,1)",
          },
          borderLeftColor:
            index % 2 === 0
              ? "rgba(193,53,132,1)"
              : index % 3 === 0
              ? "rgba(255,69,0,1)"
              : "rgba(59,89,152,1)",
        }}
        sm={5.75}
       
        //   lg={2.75}
      >
        <Grid item xs={12} sm={4} md={3.5}>
          <Avatar
            src={data?.profilePicture?.url}
            alt={data?.name}
            sx={{
              width: "100px",
              height: "100px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <Stack alignItems={"start"}>
            <Typography variant="h5" fontFamily={"monospace"}>
              {data?.name}
            </Typography>
            <Typography variant="body2" component={"p"}>
              {data?.email}
            </Typography>
            <Typography variant="body2" fontWeight={"bold"} component={"p"}>
              Phone
              <Typography variant="body2" component={"span"}>
                : {data?.contactNumber}
              </Typography>
            </Typography>
            <Typography
              variant="body2"
              fontWeight={"bold"}
              component={"address"}
            >
              Total Stock
              <Typography variant="body2" component={"span"}>
                : {data?.inStock}
              </Typography>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default VendorStockInfoCard;
