import {
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Iconify from "../../../components/iconify/Iconify";
import VendorDetails from "./VendorDetails";

const VendorMainPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  // function to switch tables
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title> Admin | All Vendors </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Vendors
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/vendor/create")}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Vendor
          </Button>
        </Stack>
        <Stack>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="All Vendors" {...a11yProps(0)} />
                <Tab label="Active Vendors" {...a11yProps(1)} />
                <Tab label="Inactive Vendors" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <div
              role="tabpanel"
              hidden={value !== 0}
              id={`simple-tabpanel-0`}
              aria-labelledby={`simple-tab-0`}
              //   {...other}
            >
              {value === 0 && (
                <Box sx={{ p: 3 }}>
                  <VendorDetails
                    title="All Vendors"
                    getApiRoute={"/vendor/list"}
                  />
                </Box>
              )}
            </div>

            <div
              role="tabpanel"
              hidden={value !== 1}
              id={`simple-tabpanel-1`}
              aria-labelledby={`simple-tab-1`}
            >
              {value === 1 && (
                <Box sx={{ p: 3 }}>
                  <VendorDetails
                    title={"Active Customers"}
                    getApiRoute={"/vendor/all/active/list"}
                  />
                </Box>
              )}
            </div>
            
            <div
              role="tabpanel"
              hidden={value !== 2}
              id={`simple-tabpanel-2`}
              aria-labelledby={`simple-tab-2`}
              //   {...other}
            >
              {value === 2 && (
                <Box sx={{ p: 3 }}>
                  <VendorDetails
                    title="In Active Customers"
                    getApiRoute={"/vendor/all/inactive/list"}
                  />
                </Box>
              )}
            </div>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default VendorMainPage;
