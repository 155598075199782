import React from "react";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const VendorStock = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  function Row(props) {
    const { stocks } = props;

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {stocks?.cupType}
          </TableCell>
          <TableCell align="right">{stocks?.sizes?.length}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Sizes
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Cup Id</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell align="right">No Of Cups</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stocks?.sizes?.map((size, cIndex) => (
                      <TableRow key={cIndex}>
                        <TableCell component="th" scope="row">
                          {size?.cupModelUniqueId}
                        </TableCell>
                        <TableCell>{`$${size?.cupPrice}`}</TableCell>
                        <TableCell align="right">
                          {size?.numberOfCups}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
  return (
    <>
      <Stack mt={2}>
        <Typography variant="h4">Vendor Stock</Typography>
        {/* <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Index</TableCell>
                <TableCell align="left">Cup Chipt Id</TableCell>
                <TableCell align="right">Item Type</TableCell>
                <TableCell align="right">Item Category</TableCell>
                <TableCell align="right">Item Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              <TableCell colSpan={5} >fullwidth</TableCell>
              </TableRow>
              {[
                {
                  cupUniqueId: "47859",
                  cupType: "Espresse",
                  cupSize: "large",
                  numberOfCups: "7896",
                },
              ].map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
               
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>

                  <TableCell align="left">{item?.cupUniqueId}</TableCell>
                  <TableCell align="right">{item?.cupType}</TableCell>
                  <TableCell align="right">{item?.cupSize}</TableCell>
                  <TableCell align="right">{item?.numberOfCups}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Cup Type</TableCell>
                <TableCell align="right">Sizes Available</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.inStock?.map((row, pIndex) => (
                <Row key={pIndex} stocks={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default VendorStock;
