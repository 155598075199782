import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
// routes

import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import Loader from "./components/loader/Loader";
import { closeSnackbar, isLoading } from "./redux/action/defaultActions";
import { baseUrl } from "./utils/apiUrl";
import app from "./utils/firebase";

// ----------------------------------------------------------------------

export default function App() {
  const messaging = getMessaging(app);
  const { snackbar } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  // useEffect(() => {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //       getToken(messaging, {
  //         vapidKey:
  //           "BJMqAYWez45C4emuDXIzBFWZaQ479JMAtdOVDI_TCz6jK5ai8mvqCX9pFVPsXIdje_Rc45Y6ozcwSPdHBuS2Vh8",
  //       }).then((currentToken) => {
  //         getNotification(currentToken);
  //         console.log(currentToken);
  //       });
  //     }
  //   });
  // }, []);

  // ------------------------------------------------------------
  const getNotification = async (notificationToken) => {
    try {
      // console.log("token",notificationToken)
      const res = await axios.post(`${baseUrl}/account/notification-token`, {
        notificationToken,
      });
      console.log("response...", res);
    } catch (error) {
      console.log(error.message);
    }
  };

  // ------------------------------------------------------------
  // onMessage(messaging, (payload) => {
  //   console.log("Message received. ", payload);
  //   console.log(payload.notification.body);
  //   new Notification(payload.notification.title, {
  //     body: payload.notification.body,
  //     icon: "https://webartdevelopers.com/blog/wp-content/uploads/2021/11/notification-bell-icon-layered-shake-animation-effect.gif",
  //   });
  //   // ...
  // });
  // ------------------------------------------------------------

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.withCredentials = true;
  return (
    <>
      <Loader />
      <Snackbar
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={snackbar?.severity}
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}
