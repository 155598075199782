import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Container, Stack, Box, Typography, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { MuiOtpInput } from "mui-one-time-password-input";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { baseUrl } from "../../utils/apiUrl";
import { logout } from "../../redux/action/adminActions";

const GenerateResetOtp = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  console.log(state);

  const dispatch = useDispatch();

  const [otp, setOtp] = React.useState("");

  const [disableconfrmButton, setDisableConfrmButton] = useState(true);

  const handleResendOtp = async () => {
    dispatch(isLoading(true));

    try {
      const res = await axios.get(`${baseUrl}/account/resend/otp`);
      //   account/resend/otp
      console.log("res==>", res);
      dispatch(isLoading(false));
      dispatch(openSnackbar("otp has been sent successfully", "success"));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };
  const handleChange = (newValue) => {
    setOtp(newValue);

    if (newValue?.length === 6) {
      setDisableConfrmButton(false);
    } else setDisableConfrmButton(true);
  };

  const resend = () => {
    handleResendOtp();
  };

  const handleConfirmOtp = async () => {
    dispatch(isLoading(true));
    console.log({ otp });
    try {
      const res = await axios.put(
        `${baseUrl}/account/otp/verify`,
        { email: state?.email, otp: Number(otp) }
      );
      if (res.status === 200) {
        dispatch(isLoading(false));
        navigate("/forget-password/update-password", {
          state: { email: state?.email },
        });
      } else {
        dispatch(isLoading(false));
        dispatch(openSnackbar("invalid otp", "error"));
        // dispatch(toggleGenerateOTPModalOpen());
      }
    } catch (error) {
      console.log({ error });
      if (error?.response?.data?.message) {
        dispatch(openSnackbar(error?.response?.data?.message, "error"));
      }
      dispatch(openSnackbar("something went wrong", "error"));
      dispatch(logout());
      dispatch(isLoading(false));
    }

    // =========================
  };
  return (
    <>
      <Container>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          height={"100vh"}
        >
          <Box
            width={{ md: "40%", sm: "70%", xs: "100%" }}
            alignSelf={"center"}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
                mb={1}
                onClick={() => navigate(-1)}
              >
                <KeyboardBackspaceIcon />{" "}
                <Typography variant="body2" component={"span"}>
                  Back
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <Typography color={"primary"} variant="h6" component="h2">
                VERIFY OTP
              </Typography>
            </Stack>
            <Typography>
              An otp has been sent to your registered email, and this otp is
              valid for 15 minutes.
            </Typography>
            <MuiOtpInput
              mt={1}
              autoFocus
              variant="filled"
              TextFieldsProps={{ size: "small" }}
              value={otp}
              length={6}
              onChange={handleChange}
            />
{/* 
            <Stack direction={"row"} mt={1} justifyContent={"end"}>
             
              <Button variant="text" color="info" onClick={resend}>
                RESEND
              </Button>
            </Stack> */}
            <Stack mt={2} >
              <Button
                disabled={disableconfrmButton}
                variant="contained"
                color="primary"
                onClick={handleConfirmOtp}
              >
                Confirm OTP and proceed
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default GenerateResetOtp;
