import { ActionTypes } from "../constants/actionTypes";

export const snacbarReducer = (
    state = { open: false, message: "", severity: "success" },
    { type, payload }
  ) => {
    switch (type) {
      case ActionTypes.OPEN_SNACKBAR:
        return { open: true, ...payload };
      case ActionTypes.CLOSE_SNACKBAR:
        return { ...state, open: false };
      default:
        return state;
    }
  };
  
  export const setIsLoading = (state = { open: false }, { type, payload }) => {
    switch (type) {
      case ActionTypes.SET_ISLOADING:
        return { open: payload };
      default:
        return state;
    }
  };