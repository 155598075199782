import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import Iconify from "../../../components/iconify/Iconify";
import SingleVendorStockCard from "./SingleVendorStockCard";
import { baseUrl } from "../../../utils/apiUrl";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

const SingleVendorStock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { vendorId } = useParams();
  const [vendorStockInfo, setVendorStockInfo] = useState(null);
  const getSingleStock = async () => {
    dispatch(isLoading(true));
    try {
      const res = await axios.get(`${baseUrl}/vendor/${vendorId}/store/stock`);
      console.log("res==>", res);
      dispatch(isLoading(false));
      setVendorStockInfo(res?.data?.vendorsStocks);
    } catch (error) {
      console.log("eeeer");
      dispatch(openSnackbar("something went wrong", "error"));
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getSingleStock();
  }, []);

  return (
    <>
      <Helmet>
        <title> Admin | Vendor Stock </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" gutterBottom>
            Single Vendor Stock
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Stack direction={{ md: "row" }}>
                <List
                  sx={{
                    width: "100%",
                    direction: "column",
                    display: { xs: "block", md: "flex" },
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "rgba(242,85,120,1)" }}>
                        <Iconify icon="ph:user" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Vendor"
                      sx={{ wordWrap: "break-word" }}
                      secondary={vendorStockInfo?.name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "rgba(80,150,254,1)" }}>
                        <Iconify icon="typcn:phone" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Phone"
                      secondary={vendorStockInfo?.contactNumber}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "rgba(80,150,254,1)" }}>
                        <Iconify icon="dashicons:email" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Email"
                      secondary={vendorStockInfo?.email}
                    />
                  </ListItem>
                </List>
              </Stack>
            </CardContent>
            {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>
          <Stack mt={2}>
            <Typography variant="h4">Stock</Typography>
          </Stack>
          <Grid mt={1} container columnGap={1} rowGap={2}>
            {vendorStockInfo?.inStock?.map((cups, index) => {
              return (
                <SingleVendorStockCard key={index} index={index} cups={cups} />
              );
            })}
          </Grid>
        </Stack>
      </Container>
    </>
  );
};

export default SingleVendorStock;
