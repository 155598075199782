import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Chip,
  InputAdornment,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import Iconify from "../iconify";
import { isLoading, openSnackbar } from "../../redux/action/defaultActions";
import { baseUrl } from "../../utils/apiUrl";
import TableToDisplayModels from "./TableToDisplayModels";

const defaultSizes = [
  { small: false },
  { medium: false },
  { large: false },
  { "extra large": false },
];

const tempErrorObject = { msg: "", status: false };

const GenerateCupModels = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [availableCupModels, setavailableCupModels] = useState([]);

  const [addSizeToDefaultSizes, setaddSizeToDefaultSizes] = useState("");
  const [errorObject, setErrorObject] = useState(tempErrorObject);
  const [cupTypeErrorObject, setcupTypeErrorObject] = useState(tempErrorObject);
  const [focusedIndex, setfocusedIndex] = useState(null);
  const [listRows, setListRows] = useState([]);

  // add new checkbox to the sizes for a perticular row
  const handleAddNewCheckboxValue = (index) => {
    const temp = [...listRows];
    // get keys ob the sizes
    const tempSizes = temp[index].sizes.map((size) => Object.keys(size)[0]);
    if (
      addSizeToDefaultSizes !== "" &&
      !tempSizes.includes(`${addSizeToDefaultSizes}`)
    ) {
      temp[index].sizes.push({ [`${addSizeToDefaultSizes}`]: false });
      setListRows([...temp]);
      setaddSizeToDefaultSizes("");
      setErrorObject(tempErrorObject);
    } else if (tempSizes.includes(addSizeToDefaultSizes)) {
      setErrorObject({ msg: "It is already added", status: true });
    } else if (addSizeToDefaultSizes === "") {
      setErrorObject({ msg: "can not add empty fields", status: true });
    }
  };

  // handleupdate the sizes
  const handleChangeSizeCheckbox = (index, sizeIndex, value) => {
    const temp = [...listRows];
    if (
      temp[index].sizes[sizeIndex][Object.keys(temp[index].sizes[sizeIndex])[0]]
    ) {
      temp[index].sizes[sizeIndex] = { [value]: false };
    } else {
      temp[index].sizes[sizeIndex] = { [value]: true };
    }
    setListRows([...temp]);
  };

  // add new rows
  const handleAddNewRow = () => {
    const temp = [...listRows];
    temp.push({ cupType: "", cupSize: "", sizes: [...defaultSizes] });
    setListRows([...temp]);
  };

  // remove rows
  const handleRemoveRow = (pos) => {
    dispatch(isLoading(true));
    const temp = [...listRows];
    temp.splice(pos, 1);
    setListRows([...temp]);
    dispatch(isLoading(false));
  };

  // set values in rows
  const handleChangeRowValues = (e, index) => {
    const temp = [...listRows];
    listRows.forEach((element) => {
      if (element.cupType.toLowerCase() === e.target.value.toLowerCase()) {
        setcupTypeErrorObject({
          msg: "already used while creation",
          status: true,
        });
        dispatch(
          openSnackbar("can't give same type to multiple fields", "error")
        );
      }
    });

    temp[index] = { ...temp[index], [e.target.name]: e.target.value };
    setListRows([...temp]);
  };

  // generate list
  const hadnleGenerateList = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    const tempList = [];
    let isSizesAvailabel = true;
    listRows.forEach((row) => {
      let counter = 0;
      row.sizes.forEach((size) => {
        if (size[Object.keys(size)[0]]) {
          tempList.push({
            cupType: row.cupType,
            cupSize: Object.keys(size)[0],
          });
          counter += 1;
        }
      });
      if (counter === 0) {
        isSizesAvailabel = false;
      }
    });

    try {
      // if (itemListLength < 100000) {
      if (isSizesAvailabel && isSizesAvailabel === true) {
        const res = await axios.post(`${baseUrl}/cup/csv/generation`, {
          cupData: tempList,
        });
        // setListRows([{ cupType: "", cupSize: "", sizes: [...defaultSizes] }]);  in olderways
        setListRows([{ cupType: "", sizes: [...defaultSizes] }]);
        getAllGeneratedModal();
        dispatch(isLoading(false));
      } else {
        dispatch(
          openSnackbar(
            "please select at least one size for each cup model",
            "error"
          )
        );
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("Something went wrong", "error"));
      }
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  // get list of generated cup models
  const getAllGeneratedModal = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.get(`${baseUrl}/cup/available/cup/csv`);
      console.log("===>", res);
      if (res.status === 200 && res.data.cupAvailable) {
        setavailableCupModels(res.data.cupAvailable);
        dispatch(isLoading(false));
      } else {
        dispatch(openSnackbar(res?.data?.message, "warning"));
        dispatch(isLoading(false));
      }
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(openSnackbar("something went wrong", "error"));
      }
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getAllGeneratedModal();
  }, []);

  return (
    <>
      <Helmet>
        <title>Add Cup Models | CHIPT NFC</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h4" gutterBottom>
            Generate Cup Models
          </Typography>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ cursor: "pointer", "&>hover": { color: "red" } }}
            mb={1}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />{" "}
            <Typography variant="body2" component={"span"}>
              Back
            </Typography>
          </Stack>
        </Stack>
        <Stack component={"form"} onSubmit={hadnleGenerateList}>
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            rowGap={2}
            columnGap={1}
          >
            {listRows?.map((listItem, index) => {
              return (
                <Grid
                  key={index}
                  item
                  container
                  display={"flex"}
                  justifyContent={"space-between"}
                  // component={Paper}

                  rowGap={2}
                  columnGap={1}
                  xs={12}
                  md={5.5}
                  p={1}
                  py={2}
                  bgcolor={"white"}
                  borderRadius={"15px"}
                  boxShadow={"2px 13px 11px -15px #afa7a7"}
                >
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="body2"
                      color={"info.dark"}
                      component={"b"}
                      fontWeight={"bold"}
                      gutterBottom
                    >
                      Row : {index + 1}
                    </Typography>
                    <IconButton
                      sx={{ color: "error.dark" }}
                      // disabled={listRows?.length === 1}
                      onClick={() => handleRemoveRow(index)}
                    >
                      <Iconify icon="gala:remove" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={5.75}>
                    <TextField
                      label="select cup modal"
                      name="cupType"
                      // select
                      error={
                        index === focusedIndex && cupTypeErrorObject.status
                      }
                      onFocus={() => {
                        setfocusedIndex(index);
                      }}
                      fullWidth
                      required
                      value={listItem?.cupType}
                      size="small"
                      onChange={(e) => {
                        setcupTypeErrorObject(tempErrorObject);
                        handleChangeRowValues(e, index);
                      }}
                      placeholder="will be assigned automatically"
                      helperText={cupTypeErrorObject?.msg}
                      // focused
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2" component={"p"}>
                      choose sizes
                    </Typography>

                    {listItem?.sizes?.map((size, sizeIndex) => (
                      <FormControlLabel
                        key={sizeIndex}
                        value={size[Object.keys(size)[0]]}
                        control={
                          <Checkbox
                            onChange={() =>
                              handleChangeSizeCheckbox(
                                index,
                                sizeIndex,
                                Object.keys(size)[0]
                              )
                            }
                          />
                        }
                        label={Object.keys(size)[0]}
                        labelPlacement="end"
                      />
                    ))}
                    <TextField
                      variant="standard"
                      placeholder="add size options"
                      error={index === focusedIndex && errorObject.status}
                      size="small"
                      onFocus={() => {
                        setfocusedIndex(index);
                        // setaddSizeToDefaultSizes("");
                      }}
                      // onBlur={() => setaddSizeToDefaultSizes("")}
                      onChange={(e) => setaddSizeToDefaultSizes(e.target.value)}
                      helperText={errorObject.msg}
                      focused={index === focusedIndex}
                      value={
                        index === focusedIndex ? addSizeToDefaultSizes : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Button
                              // label="add"
                              size="small"
                              variant="text"
                              color="warning"
                              onClick={() => handleAddNewCheckboxValue(index)}
                            >
                              Add
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <Stack alignItems={"end"} mt={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<Iconify icon="mingcute:add-fill" />}
              onClick={handleAddNewRow}
            >
              Add New Row
            </Button>
          </Stack>
          {listRows.length > 0 ? (
            <Stack alignItems={"center"} direction={"row"} spacing={2}>
              <Button variant="contained" color="primary" type="submit">
                Create Models
              </Button>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
        <Stack mt={2}>
          <Typography variant="h6">Already Added Models</Typography>
          <TableToDisplayModels cupModelsData={availableCupModels} />
        </Stack>
      </Container>
    </>
  );
};

export default GenerateCupModels;
